import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';

import ShareUnlockItem from '../Main/ShareUnlockList/ShareUnlockItem';

import styles from './OuterUnlockList.module.less';

import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import { useI18n } from '@/hooks';
import { parseCapitalToString } from '@/utils/share';

interface Props {
  resp: GetShareUnlockInfoResp;
  trigger?: boolean;
}

const OuterUnlockList: React.FC<Props> = ({ resp, trigger }) => {
  const { t } = useI18n('pages.Info.OuterUnlockList');
  const [visible, setVisible] = useState(false);

  const outerShareUnlockList = useMemo(
    () => resp.stakeholderOuterShareUnlockList.sort((a, b) => b.unlockDate.localeCompare(a.unlockDate)),
    [resp.stakeholderOuterShareUnlockList],
  );

  const nowDate = dayjs().format('YYYY-MM-DD');

  const totalCapital = parseCapitalToString(
    resp.stakeholderOuterShareUnlockList.reduce((prev, cur) => prev + cur.shareCapital, 0),
    '元',
    true,
  );

  const lockCapital = parseCapitalToString(
    resp.stakeholderOuterShareUnlockList
      .filter(v => v.unlockDate >= nowDate)
      .reduce((prev, cur) => prev + cur.shareCapital, 0),
    '元',
    true,
  );

  return (
    <div className={styles['outer-unlock-list']}>
      <div className={styles.title}>
        <span className={styles.main}>{t('unlock_list', '外部解禁列表')}</span>
        <span className={styles.sub}>{t('unlock_list.2', ' (共')}</span>
        <span className={styles['unlock-capital']}>{totalCapital}</span>
        <span className={styles.sub}>{t('unlock_list.2', ', 未解')}</span>
        <span className={styles['unlock-capital']}>{lockCapital}</span>
        <span className={styles.sub}>{t('unlock_list.3', ')')}</span>
        {trigger && outerShareUnlockList.length > 0 && (
          <div className={styles.trigger} onClick={() => setVisible(v => !v)}>
            {visible ? t('trigger.hide', '隐藏') : t('trigger.show', '展开')}
          </div>
        )}
      </div>
      {(!trigger || visible) &&
        outerShareUnlockList.map(item => <ShareUnlockItem key={item.id} className={styles.item} item={item} />)}
    </div>
  );
};

export default OuterUnlockList;
