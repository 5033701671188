import { atom } from 'recoil';

import { AuthInfo } from '@/utils/types';

export interface GlobalState {
  authInfo: AuthInfo;
  menu: {
    visible: boolean;
  };
}

export const defaultGlobalState: GlobalState = {
  authInfo: {
    id: '',
    name: '',
    username: '',
    authState: 'init',
  },
  menu: {
    visible: false,
  },
};

export const globalState = atom<GlobalState>({
  key: 'globalState',
  default: defaultGlobalState,
});
