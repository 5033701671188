import React, { CSSProperties, ReactNode } from 'react';

import { Space, Tooltip } from '@arco-design/web-react';
import { IconProps, IconQuestionCircle } from '@arco-design/web-react/icon';

export interface HelpProps {
  content: ReactNode;
  help?: ReactNode;
  style?: CSSProperties;
  iconProps?: IconProps;
}

const Help: React.FC<HelpProps> = ({ content, help, style, iconProps }) =>
  help ? (
    <Space size={2} style={style}>
      {content}
      <Tooltip content={help}>
        <IconQuestionCircle {...iconProps} />
      </Tooltip>
    </Space>
  ) : (
    <>{content}</>
  );
export default Help;
