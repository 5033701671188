import apis from '.';

export interface LoginReq {
  username: string;
  password: string;
  algorithm: string;
}

export async function login(req: LoginReq) {
  return apis.post<LoginReq, null>('/api/v1/auth/login', req);
}
