import { IconHome, IconStar } from '@arco-design/web-react/icon';
import { matchPath } from '@modern-js/runtime/router';

import Page404 from './pages/404';

import Info from '@/pages/Info';
import Main from '@/pages/Main';

export interface Route {
  text: string; // 兜底文案
  key: string; // i18n 文案 key
  path: string; // 路径
  icon?: any; // 图标
  redirect?: string; // 重定向路径
  component?: any; // 组件
  props?: any; // 组件参数
  hideInMenu?: boolean; // 为 true 表示不显示在菜单内
  children?: Route[]; // 子路由
}

// 一级路由是在顶部导航栏，一定要加上 Icon
export const routes: Route[] = [
  {
    text: '',
    key: 'menu.index',
    path: '/',
    redirect: '/home',
    hideInMenu: true,
  },
  {
    text: '主页',
    key: 'menu.home',
    icon: <IconHome />,
    path: '/home',
    component: <Main />,
  },
  {
    text: '收藏',
    key: 'menu.favorite',
    icon: <IconStar />,
    path: '/favorite',
    component: <Main isFavorite={true} />,
  },
  {
    text: '限售解禁信息',
    key: 'menu.share_unlock.info',
    path: '/share-unlock/:id',
    hideInMenu: true,
    component: <Info />,
  },
];

// 将路由树变成一维数组
export function getFlattenRoutes(): Route[] {
  const res: Route[] = [];
  function travel(rs: Route[]) {
    rs.forEach(r => {
      if (r.component || r.redirect) {
        res.push(r);
      }
      if (r.children) {
        travel(r.children);
      }
    });
  }
  travel(routes);
  return res;
}

export function matchRoute(route: Route, path: string): boolean {
  return Boolean(extractParams(route, path));
}

export function extractParams(route: Route, path: string) {
  return matchPath(path, {
    path: route.path,
    exact: true,
    strict: false,
  });
}

// 获取 root 到当前 route 的路径，自顶向下
export function getRoutePath(route?: Route): Route[] {
  const res: Route[] = [];
  if (!route) {
    return res;
  }
  function travel(rs: Route[]): boolean {
    let match = false;
    rs.forEach(r => {
      let push = false;
      if (r.key === route!.key) {
        push = true;
      }
      if (r.children) {
        push = push || travel(r.children);
      }
      if (push) {
        res.push(r);
      }
      match = match || push;
    });
    return match;
  }
  travel(routes);
  return res.reverse();
}

export const Route404: Route = {
  text: '404',
  key: 'error.404',
  component: Page404,
  path: '/error/404',
};
