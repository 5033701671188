import React from 'react';

import { IconLocation } from '@arco-design/web-react/icon';

import styles from './UnknownItem.module.less';

import { ShareUnlockListItem } from '@/apis';
import TagBadge from '@/components/TagBadge';
import { parseCapital } from '@/utils/share';

interface Props {
  item: ShareUnlockListItem;
  hideStock?: boolean;
}

const UnknownItem: React.FC<Props> = ({ item, hideStock }) => {
  const { capital, unit } = parseCapital(item.shareCapital, item.shareCapitalType);

  return (
    <div className={styles.content}>
      <div className={styles.left}>
        <div className={styles.main}>
          <div className={styles.name}>{item.stakeholderName}</div>
        </div>
        {!hideStock && (
          <div className={styles.info}>
            <div className={styles.stock}>{`${item.stockCode} ${item.stockName}`}</div>
            <IconLocation className={styles.icon} />
            <div className={styles.addr}>{item.addrShort}</div>
            {item.status.length > 0 && <TagBadge className={styles.status} text={item.status} color="red" />}
          </div>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.date}>{item.unlockDate} 解禁</div>
        <span className={styles.share}>
          <span className={styles.capital}>{capital}</span>
          <span className={styles.unit}>{unit}</span>
        </span>
      </div>
    </div>
  );
};

export default UnknownItem;
