import React, { CSSProperties } from 'react';

import { useHistory } from '@modern-js/runtime/router';
import cs from 'classnames';

import CompanyItem from './CompanyItem';
import ExecutiveItem from './ExecutiveItem';
import styles from './ShareUnlockItem.module.less';
import UnknownItem from './UnknownItem';

import { ShareUnlockListItem } from '@/apis';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  item: ShareUnlockListItem;
  hideStock?: boolean;
}

const ShareUnlockItem: React.FC<Props> = ({ className, style, item, hideStock }) => {
  const history = useHistory();

  const handleClick = (e: any) => {
    if (window.getSelection()?.type === 'Range') {
      e.stopPropagation();
      return;
    }
    history.push(`/share-unlock/${item.id}`);
  };

  const renderContent = () => {
    if (item.stakeholderType === 'executive' && item.executive) {
      return <ExecutiveItem item={item} hideStock={hideStock} />;
    }
    if (item.stakeholderType === 'company' && item.company) {
      return <CompanyItem item={item} hideStock={hideStock} />;
    }
    return <UnknownItem item={item} hideStock={hideStock} />;
  };

  return (
    <div className={cs(styles.item, className)} style={style}>
      <div onClick={handleClick}>{renderContent()}</div>
    </div>
  );
};

export default ShareUnlockItem;
