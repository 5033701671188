export const APP_NAME = process.env.APP_NAME!;
export const APP_VERSION = process.env.APP_VERSION!;

export const DEBUG = process.env.NODE_ENV === 'development';

export const MOBILE_SCREEN_MAX_WIDTH = 600; // px
export const MOBILE_SCREEN_LANDSCAPE_MAX_WIDTH = 950; // px
export const MOBILE_SCREEN_LANDSCAPE_MAX_HEIGHT = 450; // px

export const BASE_URL = undefined; // DEBUG ? 'http://localhost:9999' : undefined;
