import React, { CSSProperties } from 'react';

import { Spin } from '@arco-design/web-react';

import styles from './index.module.less';

const Loading: React.FC<{ style?: CSSProperties }> = ({ style }) => (
  <div className={styles.loading} style={style}>
    <Spin size={40} />
  </div>
);

export default Loading;
