import { useRef } from 'react';

import { useOnChange } from '.';

/**
 * 捕捉某个变量的值，类似 useRef 不更新 UI
 * @param value 变量
 * @returns current
 */
export function useStateRef<T>(value: T) {
  const ref = useRef<T>(value);

  useOnChange(() => {
    ref.current = value;
  }, [value]);

  return ref;
}
