import React, { useMemo, useState } from 'react';

import { Descriptions, Table } from '@arco-design/web-react';
import cs from 'classnames';
import dayjs from 'dayjs';

import { renderCapital, renderUnlockDate } from './common';
import styles from './InnerUnlockList.module.less';

import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import Help from '@/components/Help';
import { useI18n, useWindowSize } from '@/hooks';
import { parseCapitalToString, parseShareAmountToString } from '@/utils/share';

interface Props {
  resp: GetShareUnlockInfoResp;
  handleFavorite: (id: string, cancel: boolean) => void;
  trigger?: boolean;
}

const InnerUnlockList: React.FC<Props> = ({ resp, handleFavorite, trigger }) => {
  const { t } = useI18n('pages.Info.InnerUnlockList');
  const { width } = useWindowSize();
  const [visible, setVisible] = useState(false);

  const innerShareUnlockList = useMemo(
    () => resp.stakeholderInnerShareUnlockList.sort((a, b) => b.unlockDate.localeCompare(a.unlockDate)),
    [resp.stakeholderInnerShareUnlockList],
  );

  const nowDate = dayjs().format('YYYY-MM-DD');

  const totalCapital = parseCapitalToString(
    resp.stakeholderInnerShareUnlockList.reduce((prev, cur) => prev + cur.shareUnlockCapital, 0),
    '元',
    true,
  );

  const lockCapital = parseCapitalToString(
    resp.stakeholderInnerShareUnlockList
      .filter(v => v.unlockDate >= nowDate)
      .reduce((prev, cur) => prev + cur.shareUnlockCapital, 0),
    '元',
    true,
  );

  return (
    <div className={styles['inner-unlock-list']} style={{ borderBottom: trigger && !visible ? 'unset' : undefined }}>
      <div className={styles.title}>
        <span className={styles.main}>{t('unlock_list', '解禁列表')}</span>
        <span className={styles.sub}>{t('unlock_list.2', ' (共')}</span>
        <span className={styles['unlock-capital']}>{totalCapital}</span>
        <span className={styles.sub}>{t('unlock_list.2', ', 未解')}</span>
        <span className={styles['unlock-capital']}>{lockCapital}</span>
        <span className={styles.sub}>{t('unlock_list.3', ')')}</span>
        {trigger && innerShareUnlockList.length > 0 && (
          <div className={styles.trigger} onClick={() => setVisible(v => !v)}>
            {visible ? t('trigger.hide', '隐藏') : t('trigger.show', '展开')}
          </div>
        )}
      </div>
      {(!trigger || visible) &&
        (width <= 900 ? (
          innerShareUnlockList.map(e => (
            <div key={e.id} className={styles.item}>
              <Descriptions
                className={cs(styles.desc, styles['desc-inline'])}
                layout="inline-vertical"
                valueStyle={{ wordBreak: 'break-all' }}
                tableLayout="fixed"
                data={[
                  {
                    label: t('unlock_date', '解禁时间'),
                    value: renderUnlockDate(e, handleFavorite),
                  },
                  { label: t('unlock-amount', '解禁数量'), value: parseShareAmountToString(e.shareUnlockAmount) },
                  {
                    label: t('unlock_amountActual', '实际解禁数量'),
                    value: parseShareAmountToString(e.shareUnlockAmountActual),
                  },
                  { label: t('lock', '锁定期'), value: `${e.lockMonth}${t('lock_month', '个月')}` },
                  { label: t('progress', '进度'), value: e.progress },
                  {
                    label:
                      e.unlockDate >= nowDate ? (
                        <Help
                          content={t('unlock_capital', '解禁市值')}
                          help={t('unlock_capital.hint', '根据最新收盘价计算')}
                        />
                      ) : (
                        t('unlock_capital', '解禁市值')
                      ),
                    value: renderCapital(e),
                  },
                  { label: t('share_type', '限售股类型'), value: e.shareType },
                ]}
              />
            </div>
          ))
        ) : (
          <Table
            data={innerShareUnlockList}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: t('unlock_date', '解禁时间'),
                dataIndex: 'unlockDate',
                sorter: (a, b) => a.unlockDate.localeCompare(b.unlockDate),
                render: (_, e) => renderUnlockDate(e, handleFavorite),
              },
              {
                title: t('unlock_amount', '解禁数量'),
                dataIndex: 'shareUnlockAmount',
                sorter: (a, b) => a.shareUnlockAmount - b.shareUnlockAmount,
                render: (_, e) => parseShareAmountToString(e.shareUnlockAmount),
              },
              {
                title: t('unlock_amount_actual', '实际解禁数量'),
                dataIndex: 'shareUnlockAmountActual',
                sorter: (a, b) => a.shareUnlockAmountActual - b.shareUnlockAmountActual,
                render: (_, e) => parseShareAmountToString(e.shareUnlockAmountActual),
              },
              {
                title: t('lock_month', '锁定期'),
                dataIndex: 'lockMonth',
                render: (_, e) => `${e.lockMonth}${t('lock_month', '个月')}`,
              },
              { title: t('progress', '进度'), dataIndex: 'progress' },
              { title: t('share_type', '限售股类型'), dataIndex: 'shareType' },
              {
                title: (
                  <Help
                    content={t('unlock_capital', '解禁市值')}
                    help={t('unlock_capital.hint2', '若还未解禁，则根据最新收盘价计算')}
                  />
                ),
                dataIndex: 'shareUnlockCapital',
                sorter: (a, b) => a.shareUnlockCapital - b.shareUnlockCapital,
                render: (_, e) => renderCapital(e),
              },
            ]}
          />
        ))}
    </div>
  );
};

export default InnerUnlockList;
