import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import { Collapse, Skeleton } from '@arco-design/web-react';
import cs from 'classnames';

import ShareUnlockItem from '../Main/ShareUnlockList/ShareUnlockItem';

import styles from './TabOtherUnlockList.module.less';

import { ShareUnlockListItem } from '@/apis';
import { getStockAllShareUnlockList, GetStockAllShareUnlockListResp } from '@/apis/getStockAllShareUnlockList';
import NetError from '@/components/NetError';
import { useI18n } from '@/hooks';
import { handleError } from '@/utils/api';
import { unique } from '@/utils/iterator';
import { parseCapitalToString } from '@/utils/share';
import { LoadingState } from '@/utils/types';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  id: string;
}

const TabOtherUnlockList: React.FC<Props> = ({ className, style, id }) => {
  const { t } = useI18n('pages.Info.TabOtherUnlockList');
  const [resp, setResp] = useState<GetStockAllShareUnlockListResp>();
  const [loading, setLoading] = useState<LoadingState>('loading');

  const fetchData = async () => {
    setLoading('loading');
    const { data } = await handleError(getStockAllShareUnlockList, id);
    if (data) {
      setResp(data);
      setLoading('success');
    } else {
      setLoading('error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const shareUnlockList = useMemo(
    () =>
      resp?.stockShareUnlockList.sort((a, b) =>
        a.unlockDate === b.unlockDate ? b.shareCapital - a.shareCapital : b.unlockDate.localeCompare(a.unlockDate),
      ),
    [resp?.stockShareUnlockList],
  );

  const unlockDateList = useMemo(
    () => unique(shareUnlockList?.map(v => v.unlockDate))?.sort((a, b) => b.localeCompare(a)),
    [shareUnlockList],
  );

  const folderMap = useMemo(
    () =>
      unlockDateList?.reduce<Record<string, ShareUnlockListItem[]>>((prev, d) => {
        shareUnlockList && (prev[d] = shareUnlockList.filter(s => s.unlockDate === d));
        return prev;
      }, {}),
    [unlockDateList, shareUnlockList],
  );

  if (loading === 'error') {
    return <NetError onRetry={fetchData} />;
  }

  if (loading === 'loading' || !shareUnlockList || !unlockDateList || !folderMap) {
    return (
      <>
        <Skeleton animation text={{ rows: 4 }} style={{ margin: '12px 16px' }} />
        <Skeleton animation text={{ rows: 4 }} style={{ margin: '36px 16px 12px' }} />
        <Skeleton animation text={{ rows: 10 }} style={{ margin: '36px 16px 12px' }} />
      </>
    );
  }

  return (
    <div className={cs(styles['other-unlock-list'], className)} style={style}>
      <div className={styles['unlock-list']}>
        <div className={styles.title}>{t('unlock-list.title', '解禁批次')}</div>
        <Collapse bordered={true} defaultActiveKey={[shareUnlockList[0].unlockDate]}>
          {unlockDateList.map(unlockDate => (
            <Collapse.Item
              header={
                <div className={styles.header}>
                  <span>{`${unlockDate} (${folderMap[unlockDate].length}个, 共`}</span>
                  <span className={styles.capital}>
                    {parseCapitalToString(
                      folderMap[unlockDate].reduce((p, c) => p + c.shareCapital, 0),
                      '元',
                    )}
                  </span>
                  <span>{')'}</span>
                </div>
              }
              key={unlockDate}
              name={unlockDate}
              className={styles.item}
              contentStyle={{ padding: 0 }}
            >
              {folderMap[unlockDate].map(item => (
                <ShareUnlockItem key={item.id} item={item} hideStock={true} />
              ))}
            </Collapse.Item>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default TabOtherUnlockList;
