import { ShareUnlockListItem } from './types';

import apis from '.';

export interface QueryShareUnlockListReq {
  page?: number;
  pageSize?: number;
  search?: string;
  unlockDateStart?: string;
  unlockDateEnd?: string;
  unlockCapitalStart?: number;
  unlockCapitalEnd?: number;
  addrProvince?: string;
  addrCity?: string;
  stockCode?: string;
  isFavorite?: boolean;
  stakeholderTypeList?: string[];
  executiveSchool?: string;
  companyAddrProvince?: string;
  companyAddrCity?: string;
  sort?: string;
}

export interface QueryShareUnlockListResp {
  list: ShareUnlockListItem[];
  total: number;
}

export async function queryShareUnlockList(req: QueryShareUnlockListReq) {
  return apis.get<QueryShareUnlockListReq, QueryShareUnlockListResp>('/api/v1/share_unlock/query', {
    params: req,
  });
}
