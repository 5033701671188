import React, { CSSProperties } from 'react';

import cs from 'classnames';

import styles from './index.module.less';

type Props = {
  className?: string | string[];
  style?: CSSProperties;
  text?: string;
  color?: 'red' | 'blue' | 'green' | 'grey' | 'purple';
};

const colorMap = {
  red: { text: '#e42424', bg: 'rgba(228,36,36,.09)' },
  green: { text: '#1ea830', bg: 'rgba(30,168,48,.09)' },
  blue: { text: '#2972fa', bg: 'rgba(41,114,250,.09)' },
  grey: { text: 'rgb(var(--gray-5))', bg: 'rgb(var(--gray-1))' },
  purple: { text: 'rgb(var(--purple-5))', bg: 'rgb(var(--purple-1))' },
};

const TagBadge: React.FC<Props> = ({ className, style, text, color }) => (
  <div
    className={cs(className, styles.tag)}
    style={{ color: color && colorMap[color].text, backgroundColor: color && colorMap[color].bg, ...style }}
  >
    {text}
  </div>
);

export default TagBadge;
