import React, { CSSProperties, useMemo } from 'react';

import { Collapse, Descriptions, Table } from '@arco-design/web-react';
import cs from 'classnames';
import dayjs from 'dayjs';

import styles from './TabShare.module.less';

import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import { useI18n, useWindowSize } from '@/hooks';
import { parseCapitalToString, parseShareAmountToString } from '@/utils/share';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  resp: GetShareUnlockInfoResp;
}

const TabShare: React.FC<Props> = ({ className, style, resp }) => {
  const { t } = useI18n('pages.Info.TabShare');
  const { shareCurrent: current } = resp.stock;
  const { width } = useWindowSize();

  const shareStructureList = useMemo(
    () => resp.stock.shareStructureList.sort((a, b) => b.changeDate.localeCompare(a.changeDate)),
    [resp.stock.shareStructureList],
  );

  return (
    <div className={cs(styles.share, className)} style={style}>
      <div className={styles.title}>{t('share_price', '股票价格')}</div>
      <Descriptions
        className={cs(styles.desc, width <= 900 && styles['desc-inline'])}
        layout={width <= 900 ? 'inline-vertical' : 'horizontal'}
        border={width > 900}
        tableLayout="fixed"
        valueStyle={{ wordBreak: 'break-all' }}
        data={[
          {
            label: t('price', '当前股价'),
            value: <div className={styles.price}>{parseCapitalToString(current?.price, current?.priceType)}</div>,
          },
          {
            label: t('capital', '总市值'),
            value: parseCapitalToString(current?.capital, current?.capitalType),
          },
          {
            label: t('capital_trade', '流通市值'),
            value: parseCapitalToString(current?.capitalTrade, current?.capitalTradeType),
          },
          { label: t('status', '交易状态'), value: current ? current.status || '正常交易' : '-' },
          {
            label: t('updated_at', '更新时间'),
            value: current ? dayjs(current.updatedAt).format('YYYY-MM-DD HH:mm:ss') || '正常交易' : '-',
            span: 2,
          },
        ]}
      />
      <div className={styles['share-structure-list']}>
        <div className={styles.title}>{t('share_structure_title', '股本结构')}</div>
        {width <= 900 ? (
          <Collapse bordered={true} defaultActiveKey={[shareStructureList[0].changeDate]}>
            {shareStructureList.map(e => (
              <Collapse.Item
                header={e.changeDate}
                key={e.changeDate}
                name={e.changeDate}
                className={styles.item}
                contentStyle={{ padding: '8px 8px 8px 16px' }}
              >
                <Descriptions
                  className={cs(styles.desc, styles['desc-inline'])}
                  layout="inline-vertical"
                  valueStyle={{ wordBreak: 'break-all' }}
                  data={[
                    { label: t('change_date', '变动日期'), value: e.changeDate },
                    { label: t('publish_date', '公告日期'), value: e.publishDate },
                    { label: t('change_reason', '变动原因'), value: e.changeReason },
                    { label: t('total', '总股本'), value: parseShareAmountToString(e.total) },
                    { label: t('executive', '高管股'), value: parseShareAmountToString(e.executive) },
                    { label: t('trade_a', '流通A股'), value: parseShareAmountToString(e.tradeA) },
                    { label: t('restriction_a', '限售A股'), value: parseShareAmountToString(e.restrictionA) },
                    { label: t('trade_b', '流通B股'), value: parseShareAmountToString(e.tradeB) },
                    { label: t('restriction_b', '限售B股'), value: parseShareAmountToString(e.restrictionB) },
                    { label: t('trade_h', '流通H股'), value: parseShareAmountToString(e.tradeH) },
                    { label: t('country', '国家股'), value: parseShareAmountToString(e.country) },
                    { label: t('national', '国有法人股'), value: parseShareAmountToString(e.national) },
                    { label: t('domestic', '境内法人股'), value: parseShareAmountToString(e.domestic) },
                    {
                      label: t('domestic_originator', '境内发起人股'),
                      value: parseShareAmountToString(e.domesticOriginator),
                    },
                    { label: t('raised', '募集法人股'), value: parseShareAmountToString(e.raised) },
                    { label: t('ordinary', '一般法人股'), value: parseShareAmountToString(e.ordinary) },
                    { label: t('strategic', '战略投资者持股'), value: parseShareAmountToString(e.strategic) },
                    { label: t('fund', '基金持股'), value: parseShareAmountToString(e.fund) },
                    { label: t('transfer', '转配股'), value: parseShareAmountToString(e.transfer) },
                    { label: t('employee', '内部职工股'), value: parseShareAmountToString(e.employee) },
                    { label: t('prefer', '优先股'), value: parseShareAmountToString(e.prefer) },
                  ]}
                />
              </Collapse.Item>
            ))}
          </Collapse>
        ) : (
          <Table
            data={shareStructureList}
            pagination={false}
            tableLayoutFixed={true}
            rowKey="changeDate"
            columns={[
              {
                title: t('change_date', '变动日期'),
                dataIndex: 'changeDate',
                sorter: (a, b) => a.changeDate.localeCompare(b.changeDate),
                width: 120,
              },
              {
                title: t('publish_date', '公告日期'),
                dataIndex: 'publishDate',
                width: 120,
              },
              {
                title: t('change_reason', '变动原因'),
                dataIndex: 'changeReason',
                width: 120,
              },
              {
                title: t('total', '总股本'),
                dataIndex: 'total',
                render: (_, e) => parseShareAmountToString(e.total),
                width: 120,
              },
              {
                title: t('executive', '高管股'),
                dataIndex: 'executive',
                render: (_, e) => parseShareAmountToString(e.executive),
                width: 120,
              },
              {
                title: t('trade_a', '流通A股'),
                dataIndex: 'tradeA',
                render: (_, e) => parseShareAmountToString(e.tradeA),
                width: 120,
              },
              {
                title: t('restriction_a', '限售A股'),
                dataIndex: 'restrictionA',
                render: (_, e) => parseShareAmountToString(e.restrictionA),
                width: 120,
              },
              {
                title: t('trade_b', '流通B股'),
                dataIndex: 'tradeB',
                render: (_, e) => parseShareAmountToString(e.tradeB),
                width: 120,
              },
              {
                title: t('restriction_b', '限售B股'),
                dataIndex: 'restrictionB',
                render: (_, e) => parseShareAmountToString(e.restrictionB),
                width: 120,
              },
              {
                title: t('trade_h', '流通H股'),
                dataIndex: 'tradeH',
                render: (_, e) => parseShareAmountToString(e.tradeH),
                width: 120,
              },
              {
                title: t('country', '国家股'),
                dataIndex: 'country',
                render: (_, e) => parseShareAmountToString(e.country),
                width: 120,
              },
              {
                title: t('national', '国有法人股'),
                dataIndex: 'national',
                render: (_, e) => parseShareAmountToString(e.national),
                width: 120,
              },
              {
                title: t('domestic', '内法人股'),
                dataIndex: 'domestic',
                render: (_, e) => parseShareAmountToString(e.domestic),
                width: 120,
              },
              {
                title: t('domestic_originator', '境内发起人股'),
                dataIndex: 'domesticOriginator',
                render: (_, e) => parseShareAmountToString(e.domesticOriginator),
                width: 120,
              },
              {
                title: t('raised', '募集法人股'),
                dataIndex: 'raised',
                render: (_, e) => parseShareAmountToString(e.raised),
                width: 120,
              },
              {
                title: t('ordinary', '一般法人股'),
                dataIndex: 'ordinary',
                render: (_, e) => parseShareAmountToString(e.ordinary),
                width: 120,
              },
              {
                title: t('strategic', '战略投资者持股'),
                dataIndex: 'strategic',
                render: (_, e) => parseShareAmountToString(e.strategic),
                width: 120,
              },
              {
                title: t('fund', '基金持股'),
                dataIndex: 'fund',
                render: (_, e) => parseShareAmountToString(e.fund),
                width: 120,
              },
              {
                title: t('transfer', '转配股'),
                dataIndex: 'transfer',
                render: (_, e) => parseShareAmountToString(e.transfer),
                width: 120,
              },
              {
                title: t('employee', '内部职工股'),
                dataIndex: 'employee',
                render: (_, e) => parseShareAmountToString(e.employee),
                width: 120,
              },
              {
                title: t('prefer', '优先股'),
                dataIndex: 'prefer',
                render: (_, e) => parseShareAmountToString(e.prefer),
                width: 120,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default TabShare;
