import { Button, Result } from '@arco-design/web-react';
import { useHistory } from '@modern-js/runtime/router';

import styles from './index.module.less';

import { useI18n } from '@/hooks';

const NetError: React.FC<{
  onRetry: () => void;
  onBack?: () => void;
  title?: any;
  subTitle?: any;
}> = ({ onRetry, onBack, title, subTitle }) => {
  const { t, tt } = useI18n('common.components.NetError');

  const history = useHistory();
  return (
    <div className={styles.content}>
      <Result
        status="error"
        title={title ?? t('failed_to_get_info', '获取信息失败')}
        subTitle={subTitle ?? t('failed_to_get_info_subtitle', '抱歉，服务器出了点问题～请稍后重试~')}
        extra={[
          <Button key="again" style={{ marginRight: 16 }} onClick={() => onRetry()}>
            {tt('common.retry', '重试')}
          </Button>,
          <Button key="back" type="primary" onClick={() => (onBack ?? history.goBack)()}>
            {tt('common.back', '返回')}
          </Button>,
        ]}
      />
    </div>
  );
};

export default NetError;
