import { useRef } from 'react';

/**
 * 使用前一个值，不更新 UI
 * @param next 当前值
 * @returns 旧值
 */
export function usePrevious<T>(next: T): T | undefined;
export function usePrevious<T>(next: T, shouldSkipUndefined: true): Exclude<T, undefined> | undefined;
export function usePrevious<T>(next: T, shouldSkipUndefined?: boolean): Exclude<T, undefined> | undefined;
export function usePrevious<T>(next: T, shouldSkipUndefined?: boolean) {
  const ref = useRef<T>();
  const { current } = ref;
  if (!shouldSkipUndefined || next !== undefined) {
    ref.current = next;
  }

  return current;
}
