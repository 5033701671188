import { AnyFunction, AnyToVoidFunction } from './types';

type SchedulerFunction = typeof requestAnimationFrame | typeof onTickEnd;

export function debounce<Func extends AnyFunction>(
  func: Func,
  ms: number,
  shouldRunFirst = false,
  shouldRunLast = true,
) {
  let timeout: number | undefined;

  return (...args: Parameters<Func>) => {
    if (timeout) {
      clearTimeout(timeout);
    } else if (shouldRunFirst) {
      func(...args);
    }
    timeout = self.setTimeout(() => {
      if (shouldRunLast) {
        func(...args);
      }
      timeout = undefined;
    }, ms);
  };
}

export function throttle<Func extends AnyFunction>(func: Func, ms: number, shouldRunFirst = true) {
  let interval: number | undefined;
  let isPending: boolean;

  return (...args: Parameters<Func>) => {
    isPending = true;

    if (!interval) {
      if (shouldRunFirst) {
        isPending = false;
        func(...args);
      }

      interval = self.setInterval(() => {
        if (!isPending) {
          self.clearInterval(interval);
          interval = undefined;
          return;
        }

        isPending = false;
        func(...args);
      }, ms);
    }
  };
}

export function throttleWith<Func extends AnyToVoidFunction>(schedulerFunc: SchedulerFunction, func: Func) {
  let isCalled = false;

  return (...args: Parameters<Func>) => {
    if (!isCalled) {
      isCalled = true;

      schedulerFunc(() => {
        isCalled = false;
        func(...args);
      });
    }
  };
}

export function throttleWithRaf<Func extends AnyToVoidFunction>(func: Func) {
  return throttleWith(fastRaf, func);
}

export function throttleWithPrimaryRaf<Func extends AnyToVoidFunction>(func: Func) {
  return throttleWith(fastRafPrimary, func);
}

export function throttleWithTickEnd<Func extends AnyToVoidFunction>(func: Func) {
  return throttleWith(onTickEnd, func);
}

export function onIdle(callback: VoidFunction, timeout?: number) {
  if (self.requestIdleCallback) {
    self.requestIdleCallback(callback, { timeout });
  } else {
    onTickEnd(callback);
  }
}

export const pause = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(resolve, ms);
  });

export function rafPromise() {
  return new Promise<void>(resolve => {
    fastRaf(resolve);
  });
}

let fastRafCallbacks: VoidFunction[] | undefined;
let fastRafPrimaryCallbacks: VoidFunction[] | undefined;

// May result in an immediate execution if called from another `requestAnimationFrame` callback
export function fastRaf(callback: VoidFunction, isPrimary = false) {
  if (!fastRafCallbacks) {
    fastRafCallbacks = isPrimary ? [] : [callback];
    fastRafPrimaryCallbacks = isPrimary ? [callback] : [];

    requestAnimationFrame(() => {
      const currentCallbacks = fastRafCallbacks!;
      const currentPrimaryCallbacks = fastRafPrimaryCallbacks!;
      fastRafCallbacks = undefined;
      fastRafPrimaryCallbacks = undefined;
      currentPrimaryCallbacks.forEach(cb => cb());
      currentCallbacks.forEach(cb => cb());
    });
  } else if (isPrimary) {
    fastRafPrimaryCallbacks!.push(callback);
  } else {
    fastRafCallbacks.push(callback);
  }
}

export function fastRafPrimary(callback: VoidFunction) {
  fastRaf(callback, true);
}

let onTickEndCallbacks: VoidFunction[] | undefined;
let onTickEndPrimaryCallbacks: VoidFunction[] | undefined;

export function onTickEnd(callback: VoidFunction, isPrimary = false) {
  if (!onTickEndCallbacks) {
    onTickEndCallbacks = isPrimary ? [] : [callback];
    onTickEndPrimaryCallbacks = isPrimary ? [callback] : [];

    Promise.resolve().then(() => {
      const currentCallbacks = onTickEndCallbacks!;
      const currentPrimaryCallbacks = onTickEndPrimaryCallbacks!;
      onTickEndCallbacks = undefined;
      onTickEndPrimaryCallbacks = undefined;
      currentPrimaryCallbacks.forEach(cb => cb());
      currentCallbacks.forEach(cb => cb());
    });
  } else if (isPrimary) {
    onTickEndPrimaryCallbacks!.push(callback);
  } else {
    onTickEndCallbacks.push(callback);
  }
}

export function onTickEndPrimary(callback: VoidFunction) {
  onTickEnd(callback, true);
}
