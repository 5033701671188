import React from 'react';

import { Button, Drawer, Menu as IMenu } from '@arco-design/web-react';
import { Link, useHistory } from '@modern-js/runtime/router';
import { useRecoilState } from 'recoil';

import styles from './index.module.less';

import { logout } from '@/apis/logout';
import { useI18n } from '@/hooks';
import { defaultGlobalState, globalState } from '@/recoil/global';
import { Route, routes } from '@/route';
import { handleError } from '@/utils/api';

interface Props {
  selectedRoute?: Route;
}

const Menu: React.FC<Props> = ({ selectedRoute }) => {
  const [global, setGlobal] = useRecoilState(globalState);
  const { t, tt } = useI18n('layouts.MainLayout.Menu');
  const history = useHistory();

  const setVisible = (v: boolean) => {
    setGlobal(g => ({ ...g, menu: { ...g.menu, visible: v } }));
  };

  const handleLogout = async () => {
    const { data } = await handleError(logout);
    if (data !== undefined) {
      setGlobal(defaultGlobalState);
      history.push('/login');
    }
  };

  return (
    <Drawer
      width={250}
      title={null}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
      visible={global.menu.visible}
      placement="left"
      autoFocus={false}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className={styles.menu}>
        <div className={styles.header}>{t('hello', '你好, ') + global.authInfo.name}</div>
        <IMenu
          className={styles.content}
          onClickMenuItem={() => setVisible(false)}
          selectedKeys={selectedRoute ? [selectedRoute.key] : []}
          autoOpen={true}
        >
          {routes
            .filter(route => !route.hideInMenu)
            .map(route => (
              <IMenu.Item
                key={route.key}
                wrapper={React.forwardRef((props, _) => (
                  <Link to={route.redirect ?? route.path} {...props} />
                ))}
                style={{
                  display: 'block',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                {route.icon} {tt(route.key, route.text)}
              </IMenu.Item>
            ))}
        </IMenu>
        <div className={styles.footer}>
          <Button type="text" status="danger" onClick={handleLogout}>
            {t('logout', '退出登录')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Menu;
