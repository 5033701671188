import React, { CSSProperties } from 'react';

import { Descriptions } from '@arco-design/web-react';
import cs from 'classnames';

import { renderAddr, renderLink } from './common';
import styles from './TabStock.module.less';

import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import { useI18n, useWindowSize } from '@/hooks';
import { parseCapitalToString } from '@/utils/share';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  resp: GetShareUnlockInfoResp;
}

const TabStock: React.FC<Props> = ({ className, style, resp }) => {
  const { t } = useI18n('pages.Info.TabStock');
  const { stock } = resp;
  const { width } = useWindowSize();

  return (
    <div className={cs(styles.stock, className)} style={style}>
      <Descriptions
        className={cs(styles.desc, width <= 900 && styles['desc-inline'])}
        layout={width <= 900 ? 'inline-vertical' : 'horizontal'}
        border={width > 900}
        column={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        tableLayout="fixed"
        valueStyle={{ wordBreak: 'break-all' }}
        data={[
          { label: t('name', '公司名'), value: stock.name || '-', span: 4 },
          { label: t('name_en', '公司英文名'), value: stock.nameEn || '-', span: 4 },
          { label: t('addr', '注册地址'), value: renderAddr(stock.addr), span: 4 },
          { label: t('work_addr', '办公地址'), value: renderAddr(stock.workAddr), span: 4 },
          { label: t('build_date', '成立日期'), value: stock.buildDate || '-' },
          {
            label: t('capital', '注册资本'),
            value: parseCapitalToString(stock.capital, stock.capitalType) || '-',
          },
          { label: t('market_date', '上市日期'), value: stock.marketDate || '-' },
          { label: t('market', '上市市场'), value: stock.market || '-' },
          {
            label: t('issue_price', '发行价格'),
            value: parseCapitalToString(stock.issuePrice, stock.issuePriceType) || '-',
          },
          { label: t('underwriter', '主承销商'), value: stock.underwriter || '-' },
          { label: t('org_type', '机构类型'), value: stock.orgType || '-' },
          { label: t('org_form', '组织形式'), value: stock.orgForm || '-' },
          { label: t('sectary', '董事会秘书'), value: stock.sectary || '-' },
          { label: t('sectary_phone', '董秘电话'), value: stock.sectaryPhone || '-' },
          { label: t('sectary_fax', '董秘传真'), value: stock.sectaryFax || '-' },
          { label: t('sectary_email', '董秘电邮'), value: stock.sectaryEmail || '-' },
          { label: t('postal_code', '邮政编码'), value: stock.postalCode || '-' },
          { label: t('phone', '公司电话'), value: stock.phone || '-' },
          { label: t('fax', '公司传真'), value: stock.fax || '-' },
          { label: t('email', '公司电邮'), value: stock.email || '-' },
          {
            label: t('website', '公司网址'),
            value: renderLink(stock.website),
            span: 4,
          },
          {
            label: t('website_info', '信息披露网址'),
            value: renderLink(stock.websiteInfo),
            span: 4,
          },
          { label: t('name_history', '证券简称更名历史'), value: stock.nameHistory || '-', span: 4 },
          {
            label: t('intro', '公司简介'),
            value: <div style={{ whiteSpace: 'pre-line' }}>{stock.intro || '-'}</div>,
            span: 4,
          },
          { label: t('main_biz', '主营业务'), value: stock.mainBiz || '-', span: 4 },
        ]}
      />
    </div>
  );
};

export default TabStock;
