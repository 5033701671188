import { Message, Notification } from '@arco-design/web-react';

import i18n from './i18n';

import { Response } from '@/apis';

type ApiFunc<R> = (...args: any[]) => Promise<Response<R>>;

export const handleError = async <F extends ApiFunc<any>>(func: F, ...args: Parameters<F>): Promise<ReturnType<F>> => {
  const resp = await func(...args);
  const { code, msg, logid, data } = resp;
  if (!data || code !== 0) {
    commonError({ code, msg, logid, data });
  }
  return resp;
};

function commonError({ code, msg, logid }: { code: number; msg: string; logid?: string; data?: any }) {
  switch (code) {
    case 401:
      Message.error(i18n.t('common.need_login', '未登录'));
      location.pathname = '/login';
      break;
    default:
      Notification.error({
        title: i18n.t('common.error_code.title', '错误码: {code}', { code }),
        content: (
          <div>
            <div>{msg}</div>
            {logid && <div>{logid}</div>}
          </div>
        ),
      });
  }
}
