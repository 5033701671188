import * as crypto from 'crypto';

import React, { useEffect, useState } from 'react';

import { Button, Input, Message, Typography } from '@arco-design/web-react';
import { useRecoilState } from 'recoil';

import styles from './index.module.less';

import { init, InitResp } from '@/apis/init';
import { login } from '@/apis/login';
import { APP_NAME } from '@/consts';
import { useI18n } from '@/hooks';
import { globalState } from '@/recoil/global';
import { handleError } from '@/utils/api';

const Login: React.FC = () => {
  const { t, tt } = useI18n('pages.Login');
  const [, setGlobal] = useRecoilState(globalState);
  const [username, setUsername] = useState<string>();
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState<string>();
  const [passwordError, setPasswordError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [initData, setInitData] = useState<InitResp>();

  const initLogin = async () => {
    const { data } = await handleError(init);
    if (data) {
      setInitData(data);
    }
  };

  useEffect(() => {
    initLogin();
  }, []);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    Message.clear();
    if (!initData) {
      Message.error(t('initData.empty', '初始化错误，请刷新页面重试'));
      return;
    }
    if (!username) {
      setUsernameError(true);
      Message.error(t('handle.required', '用户名/邮箱/手机号不能为空'));
    }
    if (!password) {
      setPasswordError(true);
      Message.error(t('password.required', '密码不能为空'));
    }
    if (!username || !password) {
      return;
    }
    setSubmitting(true);
    const passwordHash = crypto.publicEncrypt(initData.publicKey, Buffer.from(password, 'utf-8'));
    const { data: resp } = await login({
      username,
      password: passwordHash.toString('base64'),
      algorithm: initData.algorithm,
    });
    if (resp !== undefined) {
      setGlobal(g => ({ ...g, authInfo: { ...g.authInfo, authState: 'init' } }));
    } else {
      setUsernameError(true);
      setPasswordError(true);
      Message.error(t('failed_to_login', '用户名或密码错误'));
    }
    setSubmitting(false);
  };

  return (
    <div className={styles.content}>
      <div className={styles.box}>
        <Typography.Title className={styles.title}>{APP_NAME}</Typography.Title>
        <form onSubmit={handleLogin} autoComplete="on">
          <Input
            className={styles.handle}
            error={usernameError}
            value={username}
            onChange={v => {
              setUsernameError(false);
              setUsername(v);
            }}
            placeholder={t('handle.placeholder', '用户名')}
          />
          <Input.Password
            className={styles.password}
            error={passwordError}
            value={password}
            autoComplete="on"
            onChange={v => {
              setPasswordError(false);
              setPassword(v);
            }}
            placeholder={t('password.placeholder', '密码')}
          />
          <Button className={styles['login-button']} type="primary" htmlType="submit" loading={submitting}>
            {tt('common.login', '登录')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
