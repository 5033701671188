import React, { useCallback, useEffect, useRef, useState } from 'react';

import { keepAlive, markEffectHookIsOnetime } from 'react-fiber-keep-alive';

import { getTitleScore } from '../Info/common';

import styles from './index.module.less';
import SearchBar from './SearchBar';
import ShareUnlockList from './ShareUnlockList';
import {
  QueryShareUnlockListParams,
  sortOrderMap,
  stakeholderTypeOptions,
  unlockCapitalOptions,
  unlockDateOptions,
} from './types';

import { ShareUnlockListItem } from '@/apis';
import { queryShareUnlockList, QueryShareUnlockListReq } from '@/apis/queryShareUnlockList';
import { useI18n } from '@/hooks';
import { handleError } from '@/utils/api';
import { removeNull } from '@/utils/iterator';

interface Props {
  isFavorite?: boolean;
}

const Main: React.FC<Props> = ({ isFavorite }) => {
  const { t } = useI18n('pages.Main');
  const [params, setParams] = useState<QueryShareUnlockListParams>({});
  const [loading, setLoading] = useState(false);
  const fetchId = useRef(0);
  const [list, setList] = useState<ShareUnlockListItem[]>([]);
  const [total, setTotal] = useState<number>();
  const filterWrapperRef = useRef<HTMLDivElement>(null);

  const fetchList = async (req: QueryShareUnlockListReq) => {
    setLoading(true);
    fetchId.current++;
    const { current } = fetchId;
    const { data } = await handleError(queryShareUnlockList, req);
    if (current === fetchId.current) {
      if (data) {
        data.list.forEach(s => {
          s.executive?.titleList?.sort((a, b) => {
            return (
              getTitleScore({ name: b, startDate: '', endDate: '', salary: 0, salaryType: '' }) -
              getTitleScore({ name: a, startDate: '', endDate: '', salary: 0, salaryType: '' })
            );
          });
        });
        setList(data.list);
        setTotal(data.total);
      }
      setLoading(false);
    }
  };

  const fetchMore = async (req: QueryShareUnlockListReq) => {
    fetchId.current++;
    const { current } = fetchId;
    const { data } = await handleError(queryShareUnlockList, req);
    if (current === fetchId.current) {
      if (data && data.list.length > 0) {
        setList([...list, ...data.list]);
      }
    }
  };

  const convertReq = (params: QueryShareUnlockListParams) => {
    const req: QueryShareUnlockListReq = {
      page: params.page,
      pageSize: params.pageSize,
      search: params.search,
      stakeholderTypeList: removeNull(params.stakeholderType?.map(s => stakeholderTypeOptions[s])),
      addrProvince: params.addrProvince,
      addrCity: params.addrCity,
      companyAddrProvince: params.companyAddrProvince,
      companyAddrCity: params.companyAddrCity,
      stockCode: params.stockCode,
      sort: params.sort && sortOrderMap[params.sort],
      isFavorite,
    };
    if (params.unlockDateOption) {
      let date = unlockDateOptions[params.unlockDateOption]();
      if (params.unlockDateOption === '自定义') {
        date = params.customUnlockDate;
      }
      req.unlockDateStart = date?.[0]?.format('YYYY-MM-DD');
      req.unlockDateEnd = date?.[1]?.format('YYYY-MM-DD');
    }
    if (params.unlockCapitalOption) {
      let capital = unlockCapitalOptions[params.unlockCapitalOption]();
      if (params.unlockCapitalOption === '自定义') {
        capital = [params.customUnlockCapitalStart, params.customUnlockCapitalEnd];
      }
      req.unlockCapitalStart = capital?.[0];
      req.unlockCapitalEnd = capital?.[1];
    }
    return req;
  };

  const loadMore = useCallback(() => {
    const p: QueryShareUnlockListParams = { ...params };
    const len = list?.length ?? 0;
    const pageSize = p.pageSize ?? 30;
    const page = Math.floor((len + pageSize - 1) / pageSize) + 1;
    p.page = page;
    p.pageSize = pageSize;
    fetchMore(convertReq(p));
  }, [params, list]);

  useEffect(
    markEffectHookIsOnetime(() => {
      if (params) {
        setList([]);
        setTotal(undefined);
        fetchList(convertReq(params));
      }
    }),
    [params, isFavorite],
  );

  return (
    <div className={styles.main}>
      <SearchBar
        defaultValue={params}
        onSearch={setParams}
        filterWrapperRef={filterWrapperRef}
        isFavorite={isFavorite}
      />
      {total !== undefined && (
        <div className={styles.result}>
          <span>{t('result_count.1', '共找到')}</span>
          <span className={styles.total}>{total}</span>
          <span>{t('result_count.2', '条数据，右上角可筛选，默认不展示过期数据')}</span>
        </div>
      )}
      <ShareUnlockList loading={loading} list={list} filterWrapperRef={filterWrapperRef} onLoadMore={loadMore} />
    </div>
  );
};

export default keepAlive<Props>(Main, props => {
  return {
    name: `main${props.isFavorite}`,
  };
});
