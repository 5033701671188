import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'query-string';
import sha1 from 'sha1';

import { BASE_URL, DEBUG } from '@/consts';
import logs from '@/utils/logs';

export * from './types';

export enum ShowType {
  Ignore = 0,
  Warn = 1,
  Error = 2,
  Info = 4,
}

export interface Response<T> {
  code: number;
  msg: string;
  data?: T;
  showType?: ShowType;
  logid?: string;
}

export class Apis {
  public async request<T = any, D = any>(config: AxiosRequestConfig<T>): Promise<Response<D>> {
    let reqAt = 0;
    try {
      config.paramsSerializer = p => qs.stringify(p);
      if (!config.headers) {
        config.headers = {};
      }
      if (BASE_URL) {
        config.baseURL = BASE_URL;
        config.withCredentials = true;
      }
      reqAt = new Date().getTime();
      const ts = Math.floor(reqAt / 1000);
      const nonce = Math.floor(Math.random() * 1000000000);
      config.headers['x-nonce'] = `${nonce}`;
      config.headers['x-timestamp'] = `${ts}`;
      config.headers['x-sign'] = sha1(`_${ts}_xalanq_${nonce}_`);
      const latency = new Date().getTime() - reqAt;
      const { data } = await axios.request<Response<D>, AxiosResponse<Response<D>>, T>(config);
      if (DEBUG) {
        logs.debug(`[${config.method}] ${config.url} (${latency}ms):`, data);
      }
      return data;
    } catch (error: any) {
      const latency = new Date().getTime() - reqAt;
      if (error.response) {
        const { data, status, statusText } = error.response;
        const { code, data: errData } = data;
        if (DEBUG) {
          logs.debug(`[${config.method}] ${config.url} (${latency}ms): ${errData}`);
        }
        if (!code) {
          return {
            code: status,
            msg: statusText,
            showType: ShowType.Error,
          };
        }
        return data;
      } else {
        logs.error(error);
        return {
          code: 504,
          msg: 'Unknown Error',
          showType: ShowType.Error,
        };
      }
    }
  }

  public async get<T = any, D = any>(url: string, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'GET',
      url,
    });
  }

  public async delete<T = any, D = any>(url: string, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'DELETE',
      url,
    });
  }

  public async head<T = any, D = any>(url: string, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'HEAD',
      url,
    });
  }

  public async options<T = any, D = any>(url: string, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'OPTIONS',
      url,
    });
  }

  public async post<T = any, D = any>(url: string, data?: T, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'POST',
      url,
      data,
    });
  }

  public async put<T = any, D = any>(url: string, data?: T, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'PUT',
      url,
      data,
    });
  }

  public async patch<T = any, D = any>(url: string, data?: T, config?: AxiosRequestConfig<T>): Promise<Response<D>> {
    return this.request<T, D>({
      ...config,
      method: 'PATCH',
      url,
      data,
    });
  }
}

const apis = new Apis();

export default apis;
