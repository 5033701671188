import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { Dropdown, Menu, Message, Skeleton, Tabs } from '@arco-design/web-react';
import { IconLaunch, IconLeft, IconLink } from '@arco-design/web-react/icon';
import { useHistory, useParams } from '@modern-js/runtime/router';
import copyToClipboard from 'copy-to-clipboard';

import styles from './index.module.less';
import TabBasicCompany from './TabBasicCompany';
import TabBasicExecutive from './TabBasicExecutive';
import TabBasicUnknown from './TabBasicUnknown';
import TabOtherUnlockList from './TabOtherUnlockList';
import TabShare from './TabShare';
import TabStock from './TabStock';
import TabStockExecutive, { TabStockExecutiveRef } from './TabStockExecutive';

import { favoriteShareUnlock } from '@/apis/favoriteShareUnlock';
import { getShareUnlockInfo, GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import icoEastmoney from '@/assets/icon/eastmoney.ico';
import icoSina from '@/assets/icon/sina.ico';
import icoSohu from '@/assets/icon/sohu.ico';
import icoTencent from '@/assets/icon/tencent.ico';
import NetError from '@/components/NetError';
import TagBadge from '@/components/TagBadge';
import { useI18n } from '@/hooks';
import { handleError } from '@/utils/api';
import { LoadingState } from '@/utils/types';

const Info: React.FC = () => {
  const { t } = useI18n('pages.Info');
  const { id: shareUnlockId } = useParams<{ id: string | undefined }>();
  const [loading, setLoading] = useState<LoadingState>('loading');
  const [resp, setResp] = useState<GetShareUnlockInfoResp>();
  const [activeTab, setActiveTab] = useState<string>('basic');
  const tabExecutiveRef = useRef<TabStockExecutiveRef>(null);
  const history = useHistory();

  const fetchData = async (enableLoading = true) => {
    if (shareUnlockId) {
      enableLoading && setLoading('loading');
      enableLoading && setResp(undefined);
      enableLoading && setActiveTab('basic');
      const { data } = await handleError(getShareUnlockInfo, shareUnlockId);
      if (data) {
        setResp(data);
        enableLoading && setLoading('success');
      } else {
        enableLoading && setLoading('error');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [shareUnlockId]);

  if (!shareUnlockId) {
    return <NetError onRetry={fetchData} />;
  }

  if (loading === 'error') {
    return <NetError onRetry={fetchData} />;
  }

  const handleBack = () => {
    if (document.referrer.indexOf(window.location.host) !== -1) {
      history.push('/home');
    } else {
      history.goBack();
    }
  };

  const handleFavorite = async (id: string, cancel: boolean) => {
    const { data } = await handleError(favoriteShareUnlock, id, { cancel });
    if (data !== undefined) {
      Message.success({
        content: cancel ? t('favorite.success.cancel', '取消收藏成功') : t('favorite.success', '收藏成功'),
        duration: 500,
      });
      fetchData(false);
    }
  };

  const handleCopyClipboard = async () => {
    let ok = false;
    try {
      const text = window.location.href;
      copyToClipboard(text);
      ok = true;
    } catch (_) {
      try {
        await navigator.clipboard.writeText(window.location.href);
        ok = true;
      } catch (_) {}
    }
    if (ok) {
      Message.success({
        content: t('copy_url.success', '已复制链接至剪切板，快去分享给别人吧！'),
        duration: 800,
      });
    } else {
      Message.error({
        content: t('copy_url.error', '复制链接失败'),
        duration: 800,
      });
    }
  };

  const menuItemStyle: CSSProperties = {
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
  };

  const renderMenuItem = (text: string, icon: string, url: string) => (
    <Menu.Item
      key={url}
      wrapper={React.forwardRef((props, _) => (
        <a href={url} target="_blank" {...props} />
      ))}
      style={menuItemStyle}
    >
      <img src={icon} style={{ width: 14, marginRight: 8 }} />
      {text}
    </Menu.Item>
  );

  const shouldShowExecutive = resp?.stakeholderType === 'executive';
  const shouldShowCompany = resp?.stakeholderType === 'company';
  const shouldShowUnknown = !shouldShowExecutive && !shouldShowCompany;

  return (
    <div className={styles.info}>
      <div className={styles['title-bar']}>
        <div className={styles.left}>
          <div className={styles['icon-btn']} onClick={handleBack}>
            <IconLeft />
          </div>
        </div>
        <div className={styles.title}>
          {resp ? (
            <div className={styles.text}>
              {`${resp.stock.stockCode} ${resp.stock.stockName}`}
              {resp.stock.shareCurrent?.status && resp.stock.shareCurrent.status.length > 0 && (
                <TagBadge className={styles.status} text={resp.stock.shareCurrent.status} color="red" />
              )}
            </div>
          ) : (
            <Skeleton
              animation
              text={{ rows: 1, width: [120], style: { display: 'flex', justifyContent: 'center' } }}
            />
          )}
        </div>
        {resp && (
          <div className={styles.right}>
            <Dropdown
              position="bl"
              trigger="click"
              droplist={
                <Menu>
                  {renderMenuItem(
                    '东方财富网',
                    icoEastmoney,
                    `https://data.eastmoney.com/stockdata/${resp.stock.stockCode}.html`,
                  )}
                  {renderMenuItem(
                    '新浪财经',
                    icoSina,
                    `http://finance.sina.com.cn/realstock/company/${resp.stock.stockSymbol}/nc.shtml`,
                  )}
                  {renderMenuItem(
                    '搜狐证券',
                    icoSohu,
                    `https://q.stock.sohu.com/cn/${resp.stock.stockCode}/index.shtml`,
                  )}
                  {renderMenuItem('腾讯证券', icoTencent, `https://gu.qq.com/${resp.stock.stockSymbol}/gp`)}
                  <Menu.Item key="copy" style={menuItemStyle} onClick={handleCopyClipboard}>
                    <IconLink style={{ marginRight: 8 }} />
                    {t('copy_url', '复制链接')}
                  </Menu.Item>
                </Menu>
              }
            >
              <div className={styles['icon-btn']}>
                <IconLaunch />
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      {resp ? (
        <div className={styles.content}>
          <Tabs
            key="main"
            className={styles.tabs}
            size="small"
            scrollPosition="center"
            destroyOnHide={false}
            activeTab={activeTab}
            onChange={setActiveTab}
            animation={false}
          >
            {shouldShowExecutive && (
              <Tabs.TabPane key="basic" title={t('tab.basic.stakeholder', '股东信息')}>
                <TabBasicExecutive resp={resp} handleFavorite={handleFavorite} />
              </Tabs.TabPane>
            )}
            {shouldShowCompany && (
              <Tabs.TabPane key="basic" title={t('tab.basic.stakeholder', '股东信息')}>
                <TabBasicCompany
                  resp={resp}
                  handleFavorite={handleFavorite}
                  searchExecutive={text => {
                    setActiveTab('executive');
                    if (tabExecutiveRef.current) {
                      tabExecutiveRef.current.search(text);
                    } else {
                      setTimeout(() => {
                        if (tabExecutiveRef.current) {
                          tabExecutiveRef.current.search(text);
                        }
                      }, 0);
                    }
                  }}
                />
              </Tabs.TabPane>
            )}
            {shouldShowUnknown && (
              <Tabs.TabPane key="basic" title={t('tab.basic.stakeholder', '股东信息')}>
                <TabBasicUnknown resp={resp} handleFavorite={handleFavorite} />
              </Tabs.TabPane>
            )}
            <Tabs.TabPane key="stock" title={t('tab.stock', '股票信息')}>
              <TabStock resp={resp} />
            </Tabs.TabPane>
            <Tabs.TabPane key="executive" title={t('tab.executive', '股票高管')}>
              <TabStockExecutive ref={tabExecutiveRef} id={shareUnlockId} />
            </Tabs.TabPane>
            <Tabs.TabPane key="share" title={t('tab.share', '股票数据')}>
              <TabShare resp={resp} />
            </Tabs.TabPane>
            <Tabs.TabPane key="other_unlock_list" title={t('tab.other_unlock_list', '其余解禁')}>
              <TabOtherUnlockList id={shareUnlockId} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      ) : (
        <Tabs key="skeleton" className={styles['tabs-skeleton']} activeTab="skeleton" destroyOnHide={false} animation>
          <Tabs.TabPane key="skeleton" title={<Skeleton animation text={{ rows: 1, width: [300] }} />}>
            <Skeleton animation text={{ rows: 4 }} style={{ margin: '12px 16px' }} />
            <Skeleton animation text={{ rows: 4 }} style={{ margin: '36px 16px 12px' }} />
            <Skeleton animation text={{ rows: 10 }} style={{ margin: '36px 16px 12px' }} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default Info;
