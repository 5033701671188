import { AnyLiteral } from './types';

type RecordByKey<T> = Record<string, T>;

export function buildRecordByKey<T extends AnyLiteral>(items: T[], key: keyof T) {
  return items.reduce((byKey: RecordByKey<T>, item: T) => {
    byKey[item[key]] = item;

    return byKey;
  }, {});
}

export function reduceRecord<T, U>(
  d: Record<string, T>,
  fn: (cur: U, item: { key: string; value: T }) => U,
  initialValue: U,
) {
  return Object.keys(d).reduce((cur, key) => fn(cur, { key, value: d[key] }), initialValue);
}

type OrderDirection = 'asc' | 'desc';
type OrderCallback<T> = (member: T) => any;

export function orderBy<T>(
  collection: T[],
  orderRule: keyof T | OrderCallback<T> | (keyof T | OrderCallback<T>)[],
  mode: OrderDirection | [OrderDirection, OrderDirection] = 'asc',
): T[] {
  function compareValues(a: T, b: T, currentOrderRule: keyof T | OrderCallback<T>, isAsc: boolean) {
    const aValue = (typeof currentOrderRule === 'function' ? currentOrderRule(a) : a[currentOrderRule]) || 0;
    const bValue = (typeof currentOrderRule === 'function' ? currentOrderRule(b) : b[currentOrderRule]) || 0;

    return isAsc ? aValue - bValue : bValue - aValue;
  }

  if (Array.isArray(orderRule)) {
    const [mode1, mode2] = Array.isArray(mode) ? mode : [mode, mode];
    const [orderRule1, orderRule2] = orderRule;
    const isAsc1 = mode1 === 'asc';
    const isAsc2 = mode2 === 'asc';

    return collection.sort((a, b) => {
      return compareValues(a, b, orderRule1, isAsc1) || compareValues(a, b, orderRule2, isAsc2);
    });
  }

  const isAsc = mode === 'asc';
  return collection.sort((a, b) => {
    return compareValues(a, b, orderRule, isAsc);
  });
}

export function unique<T>(array?: T[]): T[] | undefined {
  return array && Array.from(new Set(array));
}

export function compact<T>(array: T[]) {
  return array.filter(Boolean);
}

export function removeNull<T>(array?: T[]): Exclude<T, null | undefined>[] | undefined {
  return array && (array.filter(a => a !== undefined && a !== null) as Exclude<T, null | undefined>[]);
}

export function cloneDeep<T>(value: T): T {
  if (typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map(cloneDeep) as typeof value;
  }

  return Object.keys(value).reduce((acc, key) => {
    acc[key as keyof T] = cloneDeep(value[key as keyof T]);
    return acc;
  }, {} as T);
}
