import React from 'react';

import { IconLocation } from '@arco-design/web-react/icon';

import styles from './ExecutiveItem.module.less';

import { ShareUnlockListItem } from '@/apis';
import { IconFemale, IconMale } from '@/components/Icons';
import TagBadge from '@/components/TagBadge';
import { parseCapital } from '@/utils/share';

interface Props {
  item: ShareUnlockListItem;
  hideStock?: boolean;
}

const ExecutiveItem: React.FC<Props> = ({ item, hideStock }) => {
  const { executive } = item;

  if (!executive) {
    return <></>;
  }

  const { capital, unit } = parseCapital(item.shareCapital, item.shareCapitalType);
  return (
    <div className={styles.content}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.main}>
            <div className={styles.name}>{executive.name}</div>
            {executive.gender === '男' && <IconMale className={styles.male} />}
            {executive.gender === '女' && <IconFemale className={styles.female} />}
            <div className={styles.titles}>
              {executive.titleList.map(title => (
                <TagBadge key={title} className={styles.title} text={title} color="blue" />
              ))}
            </div>
          </div>
          {!hideStock && (
            <div className={styles.info}>
              <div className={styles.stock}>{`${item.stockCode} ${item.stockName}`}</div>
              <IconLocation className={styles.icon} />
              <div className={styles.addr}>{item.addrShort}</div>
              {item.status.length > 0 && <TagBadge className={styles.status} text={item.status} color="red" />}
            </div>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.date}>{item.unlockDate} 解禁</div>
          <span className={styles.share}>
            <span className={styles.capital}>{capital}</span>
            <span className={styles.unit}>{unit}</span>
          </span>
        </div>
      </div>
      <div className={styles.tags}>
        {executive.eduTagList.map(tag => (
          <TagBadge key={tag} className={styles.edu} text={tag} color="purple" />
        ))}
        {executive.orgTagList.map(tag => (
          <TagBadge key={tag} className={styles.org} text={tag} color="grey" />
        ))}
      </div>
    </div>
  );
};

export default ExecutiveItem;
