import React, { CSSProperties } from 'react';

import { Descriptions } from '@arco-design/web-react';
import cs from 'classnames';
import dayjs from 'dayjs';

import { renderCapital, renderExternalLink, renderUnlockDate } from './common';
import InnerUnlockList from './InnerUnlockList';
import OuterUnlockList from './OuterUnlockList';
import styles from './TabBasicUnknown.module.less';

import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import Help from '@/components/Help';
import { useI18n, useWindowSize } from '@/hooks';
import { parseShareAmountToString } from '@/utils/share';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  resp: GetShareUnlockInfoResp;
  handleFavorite: (id: string, cancel: boolean) => void;
}

const TabBasicUnknown: React.FC<Props> = ({ className, style, resp, handleFavorite }) => {
  const { t } = useI18n('pages.Info.TabBasicUnknown');
  const { shareUnlock } = resp;
  const { width } = useWindowSize();

  const nowDate = dayjs().format('YYYY-MM-DD');

  return (
    <div className={cs(styles.unknown, className)} style={style}>
      <div className={styles.top}>
        <div className={styles.name}>{shareUnlock.stakeholderName}</div>
        <div className={styles.time}>
          {dayjs(shareUnlock.updatedAt).format('YYYY-MM-DD ')}
          {t('updated_at', '更新')}
        </div>
      </div>
      <Descriptions
        className={cs(styles.desc, width <= 900 && styles['desc-inline'])}
        layout={width <= 900 ? 'inline-vertical' : 'horizontal'}
        border={width > 900}
        tableLayout="fixed"
        valueStyle={{ wordBreak: 'break-all' }}
        data={[
          { label: t('unlock_date', '本次解禁时间'), value: renderUnlockDate(shareUnlock, handleFavorite) },
          { label: t('unlock_amount', '本次解禁数量'), value: parseShareAmountToString(shareUnlock.shareUnlockAmount) },
          {
            label:
              shareUnlock.unlockDate >= nowDate ? (
                <Help
                  content={t('unlock_capital', '本次解禁市值')}
                  help={t('unlock_capital.hint', '根据最新收盘价计算')}
                />
              ) : (
                t('unlock_capital', '本次解禁市值')
              ),
            value: renderCapital(shareUnlock),
          },
          { label: t('external_link', '外部链接'), value: renderExternalLink(shareUnlock.stakeholderName), span: 4 },
        ]}
      />
      <InnerUnlockList resp={resp} handleFavorite={handleFavorite} trigger={true} />
      <OuterUnlockList resp={resp} trigger={true} />
    </div>
  );
};

export default TabBasicUnknown;
