/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';

import { Button, DatePicker, Divider, Input, InputNumber, Message, Select } from '@arco-design/web-react';
import cs from 'classnames';

import {
  QueryShareUnlockListParams,
  SortOrder,
  StakeholderTypeOption,
  UnlockCapitalOption,
  UnlockDateOption,
} from '../types';

import styles from './Filter.module.less';
import { locationMap } from './location';

import { useI18n } from '@/hooks';

interface Props {
  defaultValue: QueryShareUnlockListParams;
  onSearch: (value: QueryShareUnlockListParams) => void;
  isFavorite?: boolean;
}

const Filter: React.FC<Props> = ({ defaultValue, onSearch, isFavorite }) => {
  const { t, tt } = useI18n('pages.Main.SearchBar');
  const [params, setParams] = useState(defaultValue);

  useEffect(() => {
    setParams(defaultValue);
  }, [defaultValue]);

  const renderStakeholderType = () => {
    const orders: StakeholderTypeOption[] = ['不限', '高管', '有限合伙', '普通合伙'];
    return (
      <>
        <div className={styles.title}>{t('stakeholder_type', '股东类型')}</div>
        <div className={styles.options}>
          {orders.map(v => {
            const checked =
              (v === '不限' && params.stakeholderType === undefined) || params.stakeholderType?.includes(v);
            return (
              <div
                key={v}
                className={cs(styles.tag, checked && styles['tag-checked'])}
                onClick={() => {
                  if (!checked) {
                    setParams(p => ({
                      ...p,
                      stakeholderType:
                        v === '不限' ? undefined : [...(p.stakeholderType?.filter(t => t !== v) ?? []), v],
                    }));
                  } else {
                    setParams(p => {
                      const a = p.stakeholderType?.filter(t => t !== v);
                      return {
                        ...p,
                        stakeholderType: a?.length === 0 ? undefined : a,
                      };
                    });
                  }
                }}
              >
                {v}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderUnlockDate = () => {
    const orders: UnlockDateOption[] = [
      '未解禁',
      '未来1个月',
      '未来3个月',
      '未来6个月',
      '未来1年',
      '未来2年',
      '未来3年',
      '自定义',
    ];
    return (
      <>
        <div className={styles.title}>{t('unlock_date', '解禁时间')}</div>
        <div className={styles.options}>
          {orders.map(v => {
            const checked = (v === '未解禁' && params.unlockDateOption === undefined) || params.unlockDateOption === v;
            return (
              <div
                key={v}
                className={cs(styles.tag, checked && styles['tag-checked'])}
                onClick={() => {
                  if (!checked) {
                    setParams(p => ({
                      ...p,
                      unlockDateOption: v,
                    }));
                  } else {
                    setParams(p => ({
                      ...p,
                      unlockDateOption: '未解禁',
                    }));
                  }
                }}
              >
                {v}
              </div>
            );
          })}
        </div>
        {params.unlockDateOption === '自定义' && (
          <DatePicker.RangePicker
            className={styles['unlock-date-custom']}
            value={params.customUnlockDate}
            onChange={(_, date) => setParams(p => ({ ...p, customUnlockDate: date }))}
          />
        )}
      </>
    );
  };

  const renderUnlockCapital = () => {
    const orders: UnlockCapitalOption[] = [
      '不限',
      '100万以内',
      '100-500万',
      '500-1000万',
      '1000-5000万',
      '5000万-1亿',
      '1亿以上',
      '自定义',
    ];
    return (
      <>
        <div className={styles.title}>{t('unlock_capital', '解禁市值')}</div>
        <div className={styles.options}>
          {orders.map(v => {
            const checked =
              (v === '不限' && params.unlockCapitalOption === undefined) || params.unlockCapitalOption === v;
            return (
              <div
                key={v}
                className={cs(styles.tag, checked && styles['tag-checked'])}
                onClick={() => {
                  if (!checked) {
                    setParams(p => ({
                      ...p,
                      unlockCapitalOption: v,
                    }));
                  } else {
                    setParams(p => ({
                      ...p,
                      unlockCapitalOption: '不限',
                    }));
                  }
                }}
              >
                {v}
              </div>
            );
          })}
        </div>
        {params.unlockCapitalOption === '自定义' && (
          <div className={styles.capital}>
            <InputNumber
              className={styles.input}
              min={0}
              suffix="万元"
              step={1}
              value={params.customUnlockCapitalStart}
              onChange={v => setParams(p => ({ ...p, customUnlockCapitalStart: v }))}
            />
            <div className={styles.text}>至</div>
            <InputNumber
              className={styles.input}
              min={0}
              suffix="万元"
              step={1}
              value={params.customUnlockCapitalEnd}
              onChange={v => setParams(p => ({ ...p, customUnlockCapitalEnd: v }))}
            />
          </div>
        )}
      </>
    );
  };

  const renderSort = () => {
    const orders: SortOrder[] = ['默认', '市值高的在前', '市值低的在前', '解禁早的在前', '解禁晚的在前'];
    if (isFavorite) {
      orders.push('先收藏的在前', '后收藏的在前');
    }
    return (
      <>
        <div className={styles.title}>{t('sort_order', '展示顺序')}</div>
        <div className={styles.options}>
          {orders.map(v => {
            const checked = (v === '默认' && params.sort === undefined) || params.sort === v;
            return (
              <div
                key={v}
                className={cs(styles.tag, checked && styles['tag-checked'])}
                onClick={() => {
                  if (!checked) {
                    setParams(p => ({
                      ...p,
                      sort: v,
                    }));
                  } else {
                    setParams(p => ({
                      ...p,
                      sort: '默认',
                    }));
                  }
                }}
              >
                {v}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderLocation = () => {
    return (
      <>
        <div className={styles.title}>{t('address', '注册地址')}</div>
        <div className={styles.location}>
          <Select
            className={styles.province}
            allowClear
            value={params.addrProvince}
            onChange={v => setParams(p => ({ ...p, addrProvince: v, addrCity: undefined }))}
            placeholder={t('province', '省/自治区/直辖市')}
          >
            {Object.keys(locationMap).map(province => (
              <Select.Option key={province} value={province}>
                {province}
              </Select.Option>
            ))}
          </Select>
          <Select
            className={styles.city}
            allowClear
            disabled={params.addrProvince === undefined}
            value={params.addrCity}
            onChange={v => setParams(p => ({ ...p, addrCity: v }))}
            placeholder={t('city', '市/区')}
          >
            {params.addrProvince &&
              locationMap[params.addrProvince]?.map(city => (
                <Select.Option key={city} value={city}>
                  {city}
                </Select.Option>
              ))}
          </Select>
        </div>
      </>
    );
  };

  const renderCompanyLocation = () => {
    return (
      <>
        <div className={styles.title}>{t('company_address', '企业股东注册地址')}</div>
        <div className={styles.location}>
          <Select
            className={styles.province}
            allowClear
            value={params.companyAddrProvince}
            onChange={v => setParams(p => ({ ...p, companyAddrProvince: v, companyAddrCity: undefined }))}
            placeholder={t('province', '省/自治区/直辖市')}
          >
            {Object.keys(locationMap).map(province => (
              <Select.Option key={province} value={province}>
                {province}
              </Select.Option>
            ))}
          </Select>
          <Select
            className={styles.city}
            allowClear
            disabled={params.companyAddrProvince === undefined}
            value={params.companyAddrCity}
            onChange={v => setParams(p => ({ ...p, companyAddrCity: v }))}
            placeholder={t('city', '市/区')}
          >
            {params.companyAddrProvince &&
              locationMap[params.companyAddrProvince]?.map(city => (
                <Select.Option key={city} value={city}>
                  {city}
                </Select.Option>
              ))}
          </Select>
        </div>
      </>
    );
  };

  const isStockCodeError = params.stockCode !== undefined && !params.stockCode.match(/^[0-9]*$/);

  const renderStock = () => {
    return (
      <>
        <div className={styles.title}>{t('stock', '股票代码')}</div>
        <div className={styles.stock}>
          <Input
            className={styles.input}
            allowClear
            placeholder={t('stock.placeholder', '六位数代码')}
            value={params.stockCode}
            onChange={v => setParams(p => ({ ...p, stockCode: v }))}
            error={isStockCodeError}
          />
        </div>
      </>
    );
  };

  const renderButton = () => {
    return (
      <div className={styles['btn-group']}>
        <Button
          className={styles.btn}
          type="secondary"
          onClick={() =>
            setParams(p => ({
              ...p,
              unlockDateOption: undefined,
              customUnlockDate: undefined,
              unlockCapitalOption: undefined,
              customUnlockCapitalStart: undefined,
              customUnlockCapitalEnd: undefined,
              addrProvince: undefined,
              addrCity: undefined,
              stockCode: undefined,
            }))
          }
        >
          {tt('common.reset', '重置')}
        </Button>
        <Button className={styles.btn} type="primary" onClick={handleSubmit}>
          {tt('common.search', '搜索')}
        </Button>
      </div>
    );
  };

  const handleSubmit = () => {
    if (isStockCodeError) {
      Message.error(t('stock_code.error', '股票代码必须为六位数字'));
      return;
    }
    onSearch(params);
  };

  return (
    <div className={styles.filter}>
      <div className={styles.content}>
        {renderStakeholderType()}
        <Divider />
        {renderUnlockCapital()}
        <Divider />
        {renderUnlockDate()}
        <Divider />
        {renderSort()}
        <Divider />
        {renderLocation()}
        <Divider />
        {renderCompanyLocation()}
        <Divider />
        {renderStock()}
      </div>
      <div className={styles.footer}>{renderButton()}</div>
    </div>
  );
};

export default Filter;
