import React from 'react';

import { Result, Button } from '@arco-design/web-react';
import { useHistory } from '@modern-js/runtime/router';

import styles from './index.module.less';

import { useI18n } from '@/hooks';

const Page404: React.FC = () => {
  const { t, tt } = useI18n('pages.404');
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Result
        status="404"
        subTitle={t('subtitle', '抱歉，页面不见了～')}
        extra={
          <Button key="back" type="primary" onClick={() => history.goBack()}>
            {tt('common.back', '返回')}
          </Button>
        }
      />
    </div>
  );
};

export default Page404;
