import React, { useMemo, useState } from 'react';

import { Descriptions, Table } from '@arco-design/web-react';
import cs from 'classnames';

import styles from './ExecutiveTitleList.module.less';

import { ExecutiveInfo } from '@/apis';
import { useI18n, useWindowSize } from '@/hooks';
import { parseCapitalToString } from '@/utils/share';

type Props = {
  executive: ExecutiveInfo;
  trigger?: boolean;
  hideTitle?: boolean;
};

const ExecutiveTitleList: React.FC<Props> = ({ executive, trigger, hideTitle }) => {
  const { t } = useI18n('pages.Info.ExecutiveTitleList');
  const { width } = useWindowSize();
  const [visible, setVisible] = useState(false);

  const titleList = useMemo(
    () => executive.titleList.sort((a, b) => b.startDate.localeCompare(a.startDate)),
    [executive],
  );

  return (
    <div className={styles['title-list']}>
      {!hideTitle && (
        <div className={styles.title}>
          {t('title.title', '任职信息')}
          {trigger && (
            <div className={styles.trigger} onClick={() => setVisible(v => !v)}>
              {visible ? t('title.hide', '隐藏') : t('title.show', '展开')}
            </div>
          )}
        </div>
      )}
      {(!trigger || visible) &&
        (width <= 900 ? (
          titleList?.map(e => (
            <div key={e.startDate + e.name + e.endDate} className={styles.item}>
              <Descriptions
                className={cs(styles.desc, styles['desc-inline'])}
                layout="inline-vertical"
                valueStyle={{ wordBreak: 'break-all' }}
                tableLayout="fixed"
                column={{
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 3,
                  xxl: 4,
                }}
                data={[
                  { label: t('name', '职务'), value: e.name },
                  { label: t('salary', '报酬'), value: parseCapitalToString(e.salary, '元') },
                  { label: t('start_date', '任职日期'), value: e.startDate || '-' },
                  { label: t('end_date', '离职日期'), value: e.endDate || '-' },
                ]}
              />
            </div>
          ))
        ) : (
          <Table
            data={titleList}
            pagination={false}
            rowKey={e => e.startDate + e.name + e.endDate}
            columns={[
              {
                title: t('name', '职务'),
                dataIndex: 'name',
              },
              {
                title: t('start_date', '任职日期'),
                dataIndex: 'startDate',
                sorter: (a, b) => a.startDate.localeCompare(b.startDate),
              },
              {
                title: t('end_date', '离职日期'),
                dataIndex: 'endDate',
                sorter: (a, b) => a.endDate.localeCompare(b.endDate),
              },
              {
                title: t('salary', '报酬'),
                dataIndex: 'salary',
                sorter: (a, b) => a.salary - b.salary,
                render: (_, e) => parseCapitalToString(e.salary, '元'),
              },
            ]}
          />
        ))}
    </div>
  );
};

export default ExecutiveTitleList;
