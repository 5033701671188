import React, { CSSProperties, useState } from 'react';

import { Descriptions, Table } from '@arco-design/web-react';
import cs from 'classnames';
import dayjs from 'dayjs';

import { renderAddr, renderCapital, renderExternalLink, renderSharePercent, renderUnlockDate } from './common';
import InnerUnlockList from './InnerUnlockList';
import OuterUnlockList from './OuterUnlockList';
import styles from './TabBasicCompany.module.less';

import { CompanyShareholderListItem } from '@/apis';
import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import Help from '@/components/Help';
import TagBadge from '@/components/TagBadge';
import { useI18n, useWindowSize } from '@/hooks';
import { unique } from '@/utils/iterator';
import { parseCapitalToString, parseShareAmountToString } from '@/utils/share';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  resp: GetShareUnlockInfoResp;
  handleFavorite: (id: string, cancel: boolean) => void;
  searchExecutive: (text: string) => void;
}

const TabBasicCompany: React.FC<Props> = ({ className, style, resp, handleFavorite, searchExecutive }) => {
  const { t } = useI18n('pages.Info.TabBasicCompany');
  const { company, shareUnlock } = resp;
  const { width } = useWindowSize();
  const [shareholderVisible, setShareholderVisible] = useState(false);

  if (!company) {
    return <></>;
  }

  const nowDate = dayjs().format('YYYY-MM-DD');

  const renderPossible = (item: CompanyShareholderListItem) => {
    const e = company.executiveList?.find(v => v.name === item.name);
    if (!e) {
      return <></>;
    }
    return (
      <div className={cs('arco-link', 'arco-link-hoverless', styles.jump)} onClick={() => searchExecutive(e.name)}>
        {t('possible_executive', '疑似该上市股票高管，点击查看')}
      </div>
    );
  };

  const renderEduTags = (item: CompanyShareholderListItem) => {
    const e = company.executiveList?.find(v => v.name === item.name);
    if (!e) {
      return <></>;
    }
    return (
      <div className={styles.tags}>
        {unique(e.eduTagList.map(tag => tag.text))?.map(tag => (
          <TagBadge key={tag} className={styles.edu} text={tag} color="purple" />
        ))}
      </div>
    );
  };

  return (
    <div className={cs(styles.company, className)} style={style}>
      <div className={styles.top}>
        <div className={styles.name}>{shareUnlock.stakeholderName}</div>
        <div className={styles.time}>
          {dayjs(shareUnlock.updatedAt).format('YYYY-MM-DD ')}
          {t('updated_at', '更新')}
        </div>
      </div>
      <Descriptions
        className={cs(styles.desc, width <= 900 && styles['desc-inline'])}
        layout={width <= 900 ? 'inline-vertical' : 'horizontal'}
        border={width > 900}
        tableLayout="fixed"
        valueStyle={{ wordBreak: 'break-all' }}
        data={[
          { label: t('unlock_date', '本次解禁时间'), value: renderUnlockDate(shareUnlock, handleFavorite) },
          { label: t('unlock_amount', '本次解禁数量'), value: parseShareAmountToString(shareUnlock.shareUnlockAmount) },
          {
            label:
              shareUnlock.unlockDate >= nowDate ? (
                <Help
                  content={t('unlock_capital', '本次解禁市值')}
                  help={t('unlock_capital.hint', '根据最新收盘价计算')}
                />
              ) : (
                t('unlock_capital', '本次解禁市值')
              ),
            value: renderCapital(shareUnlock),
          },
          { label: t('name', '企业名称'), value: company.name || '-', span: 4 },
          { label: t('legal_person.partner', '执行事务合伙人'), value: company.legalPerson || '-', span: 4 },
          { label: t('unified_code', '统一社会信用代码'), value: company.unifiedCode || '-' },
          { label: t('status', '经营状态'), value: company.status || '-' },
          { label: t('build_date', '成立日期'), value: company.buildDate || '-' },
          {
            label: t('capital', '注册资本'),
            value: parseCapitalToString(company.capital, company.capitalType) || '-',
          },
          {
            label: t('capital_real', '实缴资本'),
            value: parseCapitalToString(company.capitalReal, company.capitalRealType) || '-',
          },
          { label: t('type', '企业类型'), value: company.type || '-' },
          { label: t('industry', '所属行业'), value: company.industry || '-' },
          { label: t('qualification', '纳税人资质'), value: company.qualification || '-' },
          { label: t('insurance_num', '参保人数'), value: `${company.insuranceNum}人` || '-' },
          { label: t('registration_agency', '登记机关'), value: company.registrationAgency || '-', span: 4 },
          { label: t('name_history', '曾用名'), value: company.nameHistory.join(', ') || '-', span: 4 },
          { label: t('addr', '注册地址'), value: renderAddr(company.addr), span: 4 },
          { label: t('biz_scope', '经营范围'), value: company.bizScope || '-', span: 4 },
          { label: t('external_link', '外部链接'), value: renderExternalLink(company.name), span: 4 },
        ]}
      />
      <div className={styles.title}>
        <span className={styles.main}>{t('shareholder.title', '合伙人信息')}</span>
        <span className={styles.sub}>
          {t('shareholder.count', ' (共{num}人)', { num: company.shareholderList?.length ?? 0 })}
        </span>
        {(company.shareholderList?.length ?? 0) > 0 && (
          <div className={styles.trigger} onClick={() => setShareholderVisible(v => !v)}>
            {shareholderVisible ? t('title.hide', '隐藏') : t('title.show', '展开')}
          </div>
        )}
      </div>
      {width <= 900
        ? shareholderVisible &&
          company.shareholderList?.map(e => (
            <div key={e.name} className={styles.item}>
              <Descriptions
                className={cs(styles.desc, styles['desc-inline'])}
                layout="inline-vertical"
                valueStyle={{ wordBreak: 'break-all' }}
                tableLayout="fixed"
                data={[
                  {
                    label: t('shareholder.name', '姓名'),
                    value: e.name,
                  },
                  {
                    label: t('shareholder.percent', '持股比例'),
                    value: renderSharePercent(e.shareAmount, company.capital),
                  },
                  {
                    label: t('shareholder.amount', '认缴出资额'),
                    value: parseCapitalToString(e.shareAmount, company.capitalRealType),
                  },
                ]}
              />
              {renderPossible(e)}
              {renderEduTags(e)}
            </div>
          ))
        : shareholderVisible && (
            <Table
              data={company.shareholderList}
              pagination={false}
              rowKey="name"
              columns={[
                {
                  title: t('shareholder.name', '姓名'),
                  dataIndex: 'name',
                  sorter: (a, b) => a.name.localeCompare(b.name),
                  render: (_, e) => (
                    <div className={styles.name}>
                      <div className={styles['inline-name']}>
                        <div className={styles.name}>{e.name}</div>
                        {renderPossible(e)}
                      </div>
                      {renderEduTags(e)}
                    </div>
                  ),
                },
                {
                  title: t('shareholder.percent', '持股比例'),
                  dataIndex: 'percent',
                  sorter: (a, b) => a.percent - b.percent,
                  render: (_, e) => renderSharePercent(e.shareAmount, company.capital),
                },
                {
                  title: t('shareholder.amount', '认缴出资额'),
                  dataIndex: 'shareAmount',
                  sorter: (a, b) => a.shareAmount - b.shareAmount,
                  render: (_, e) => parseCapitalToString(e.shareAmount, company.capitalRealType),
                },
              ]}
            />
          )}
      <InnerUnlockList resp={resp} handleFavorite={handleFavorite} trigger={true} />
      <OuterUnlockList resp={resp} trigger={true} />
    </div>
  );
};

export default TabBasicCompany;
