import apis, { ExecutiveInfo, ShareUnlockInfo, ShareUnlockListItem, StakeholderType, StockInfo, CompanyInfo } from '.';

export interface GetShareUnlockInfoResp {
  isFavorite: boolean;
  shareUnlock: ShareUnlockInfo;
  stock: StockInfo;
  stakeholderType: StakeholderType;
  stakeholderInnerShareUnlockList: ShareUnlockInfo[];
  stakeholderOuterShareUnlockList: ShareUnlockListItem[];
  executive?: ExecutiveInfo;
  company?: CompanyInfo;
}

export async function getShareUnlockInfo(id: string) {
  return apis.get<null, GetShareUnlockInfoResp>(`/api/v1/share_unlock/${id}`);
}
