import { useTranslation } from 'react-i18next';

/**
 * 使用文案国际化能力
 * @param keyPrefix 组件文案 key 的前缀
 * @returns t 表示当前组件的文案（key 带有前缀），tt 表示全局文案（key 无任何前缀）
 */
export function useI18n(keyPrefix?: string) {
  const { t: tt } = useTranslation();
  const t = (key: string, defaultValue?: string, options?: any) => tt(`${keyPrefix}.${key}`, defaultValue, options);

  return { t, tt };
}
