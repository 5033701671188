import { CSSProperties } from 'react';

import cs from 'classnames';

import { ReactComponent as FemaleComponent } from '@/assets/icon/icon_female.svg';
import { ReactComponent as MaleComponent } from '@/assets/icon/icon_male.svg';

type IconProps = {
  className?: string | string[] | undefined;
  style?: CSSProperties;
};

export const IconMale: React.FC<IconProps> = ({ className, style }) => (
  <MaleComponent className={cs('arco-icon', className)} style={style} />
);

export const IconFemale: React.FC<IconProps> = ({ className, style }) => (
  <FemaleComponent className={cs('arco-icon', className)} style={style} />
);
