import { usePrevious } from '.';

/**
 * 和 useEffect 差不多，只不过不更新 UI
 * @param cb 回调函数
 * @param dependencies 依赖
 */
export const useOnChange = <T extends readonly any[]>(cb: (args: T | readonly []) => void, dependencies: T) => {
  const prevDeps = usePrevious<T>(dependencies);
  if (!prevDeps || dependencies.some((d, i) => d !== prevDeps[i])) {
    cb(prevDeps || []);
  }
};
