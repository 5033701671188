import React, { useEffect } from 'react';

import { Redirect, Route as IRoute, Switch } from '@modern-js/runtime/router';

import styles from './index.module.less';

import { refreshToken } from '@/apis/refreshToken';
import { Route, Route404 } from '@/route';

interface Props {
  routes: Route[];
}

const Content: React.FC<Props> = ({ routes }) => {
  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <div className={styles.content}>
      <Switch>
        {routes.map(route =>
          route.redirect ? (
            <Redirect exact={true} key={route.key} from={route.path} to={route.redirect} />
          ) : (
            <IRoute exact={true} key={route.key} path={route.path}>
              {route.component}
            </IRoute>
          ),
        )}
        <IRoute exact={true} key={Route404.key} path={Route404.path} component={Route404.component} />
        <Redirect from="*" to={Route404.path} />
      </Switch>
    </div>
  );
};

export default Content;
