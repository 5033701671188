import React, { CSSProperties, useEffect, useImperativeHandle, useState } from 'react';

import { Collapse, Empty, Input, Skeleton } from '@arco-design/web-react';
import cs from 'classnames';
import dayjs from 'dayjs';

import {
  getExecutiveScore,
  getTitleScore,
  renderExecutiveIntro,
  renderExecutiveTags,
  renderExternalLink,
} from './common';
import ExecutiveTitleList from './ExecutiveTitleList';
import styles from './TabStockExecutive.module.less';

import { getStockAllExecutiveList, GetStockAllExecutiveListResp } from '@/apis/getStockAllExecutiveList';
import { IconFemale, IconMale } from '@/components/Icons';
import NetError from '@/components/NetError';
import { useI18n, useStateRef } from '@/hooks';
import { handleError } from '@/utils/api';
import { LoadingState } from '@/utils/types';

export interface TabStockExecutiveRef {
  search: (text: string) => void;
}

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  id: string;
}

const TabStockExecutive = React.forwardRef<TabStockExecutiveRef, Props>(({ className, style, id }, ref) => {
  const { t } = useI18n('pages.Info.TabStockExecutive');
  const [resp, setResp] = useState<GetStockAllExecutiveListResp>();
  const [loading, setLoading] = useState<LoadingState>('loading');
  const [search, setSearch] = useState<string>();
  const [activeKey, setActiveKey] = useState<string[]>();
  const searchRef = useStateRef(search);

  useImperativeHandle(
    ref,
    () => ({
      search: (text: string) => {
        setSearch(text);
        const e = resp?.executiveList?.find(e => e.name === text);
        if (e) {
          setActiveKey([e.id]);
        }
      },
    }),
    [resp],
  );

  const fetchData = async () => {
    setLoading('loading');
    const { data } = await handleError(getStockAllExecutiveList, id);
    if (data) {
      data.executiveList.forEach(v => v.titleList.sort((a, b) => getTitleScore(b) - getTitleScore(a)));
      data.executiveList.sort((a, b) => getExecutiveScore(b) - getExecutiveScore(a));
      setResp(data);
      setLoading('success');
      setTimeout(() => {
        const e = data?.executiveList?.find(e => e.name === searchRef.current);
        if (e) {
          setActiveKey([e.id]);
        }
      }, 0);
    } else {
      setLoading('error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading === 'error') {
    return <NetError onRetry={fetchData} />;
  }

  if (loading === 'loading' || !resp) {
    return (
      <>
        <Skeleton animation text={{ rows: 4 }} style={{ margin: '12px 16px' }} />
        <Skeleton animation text={{ rows: 4 }} style={{ margin: '36px 16px 12px' }} />
        <Skeleton animation text={{ rows: 10 }} style={{ margin: '36px 16px 12px' }} />
      </>
    );
  }

  const executiveList = resp.executiveList.filter(
    e =>
      !search ||
      e.name.toLowerCase().includes(search.toLowerCase()) ||
      e.intro.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <div className={cs(styles['stock-executive'], className)} style={style}>
      <Input.Search
        className={styles.search}
        placeholder={t('search.placeholder', '搜索名称或简历内容')}
        value={search}
        allowClear
        onChange={v => setSearch(v)}
      />
      {executiveList.length === 0 && (
        <Empty
          className={styles.empty}
          description={
            <div>
              <div>{t('empty.0', '暂无数据')}</div>
              <div>{t('empty.2', '请在搜索框内重新填写搜索内容')}</div>
            </div>
          }
        />
      )}
      {executiveList.length > 0 && (
        <div className={styles.list}>
          {search && (
            <div className={styles.hint}>{t('result.hint', '结果为搜索结果，若想查看全部高管请清空搜索框')}</div>
          )}
          <Collapse bordered={true} activeKey={activeKey} onChange={(_, keys) => setActiveKey(keys)}>
            {executiveList.map(executive => (
              <Collapse.Item
                header={
                  <div className={styles.header}>
                    <div className={styles.name}>{executive.name}</div>
                    {executive.gender === '男' && <IconMale className={styles.male} />}
                    {executive.gender === '女' && <IconFemale className={styles.female} />}
                    <div className={styles.age}>
                      {dayjs().diff(dayjs(executive.birth), 'year')}
                      {t('age', '岁')}
                    </div>
                    {renderExecutiveTags(executive)}
                  </div>
                }
                key={executive.id}
                name={executive.id}
                className={styles.item}
                contentStyle={{ padding: 0 }}
              >
                <div className={styles.content}>
                  {renderExecutiveIntro(executive)}
                  <div style={{ height: 4 }} />
                  {renderExternalLink(executive.name)}
                  <div style={{ height: 4 }} />
                  <ExecutiveTitleList executive={executive} hideTitle={true} />
                </div>
              </Collapse.Item>
            ))}
          </Collapse>
        </div>
      )}
    </div>
  );
});

export default TabStockExecutive;
