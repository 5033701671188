import React from 'react';

import { Grid } from '@arco-design/web-react';
import { IconLocation } from '@arco-design/web-react/icon';

import styles from './CompanyItem.module.less';

import { ShareUnlockListItem } from '@/apis';
import TagBadge from '@/components/TagBadge';
import { useI18n, useWindowSize } from '@/hooks';
import { parseCapital, parseCapitalToString } from '@/utils/share';

interface Props {
  item: ShareUnlockListItem;
  hideStock?: boolean;
}

const CompanyItem: React.FC<Props> = ({ item, hideStock }) => {
  const { t } = useI18n('pages.Main.ShareUnlockList');
  const { company } = item;
  const { width } = useWindowSize();

  if (!company) {
    return <></>;
  }

  const { capital, unit } = parseCapital(item.shareCapital, item.shareCapitalType);

  const companyData = [
    { label: t('legal_person.partner', '执行事务合伙人'), value: company.legalPerson },
    {
      label: t('capital', '注册资本'),
      value: parseCapitalToString(company.capital, company.capitalType),
    },
    {
      label: t('build_date', '成立时间'),
      value: company.buildDate,
    },
  ];

  return (
    <div className={styles.content}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.main}>
            <div className={styles.name}>
              <span className={styles.text}>{item.stakeholderName}</span>
              {company.status.length > 0 && company.status !== '开业' && (
                <TagBadge className={styles.status} text={company.status} color="red" />
              )}
              <IconLocation className={styles.icon} />
              <span className={styles.addr}>{company.addrShort}</span>
            </div>
          </div>
          {!hideStock && (
            <div className={styles.info}>
              <div className={styles.stock}>{`${item.stockCode} ${item.stockName}`}</div>
              <IconLocation className={styles.icon} />
              <div className={styles.addr}>{item.addrShort}</div>
              {item.status.length > 0 && <TagBadge className={styles.status} text={item.status} color="red" />}
            </div>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.date}>{item.unlockDate} 解禁</div>
          <span className={styles.share}>
            <span className={styles.capital}>{capital}</span>
            <span className={styles.unit}>{unit}</span>
          </span>
        </div>
      </div>
      {width <= 800 && (
        <Grid.Row className={styles.bottom}>
          {companyData.map(v => (
            <Grid.Col
              span={8}
              key={v.label}
              className={styles.item}
              style={{ fontSize: width <= 600 ? undefined : 12 }}
            >
              <div className={styles.label}>{v.label}</div>
              <div className={styles.value}>{v.value}</div>
            </Grid.Col>
          ))}
        </Grid.Row>
      )}
      {width > 800 && (
        <div className={styles['bottom-big']}>
          {companyData.map(v => (
            <div key={v.label} className={styles.item}>
              <div className={styles.label}>{v.label}: </div>
              <div className={styles.value}>{v.value}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanyItem;
