import { IconStar, IconStarFill } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import qs from 'query-string';

import styles from './common.module.less';

import { ExecutiveInfo, ShareUnlockInfo, TitleListItem } from '@/apis';
import icoAiqicha from '@/assets/icon/aiqicha.ico';
import icoQichacha from '@/assets/icon/qichacha.ico';
import icoTianyancha from '@/assets/icon/tianyancha.ico';
import TagBadge from '@/components/TagBadge';
import { unique } from '@/utils/iterator';
import { parseCapitalToString } from '@/utils/share';

export const renderExternalLink = (keyword: string) => {
  return (
    <div className={styles['external-link']}>
      {[
        {
          icon: icoAiqicha,
          label: '爱企查',
          url: `https://aiqicha.baidu.com/s?${qs.stringify({ q: keyword })}`,
        },
        {
          icon: icoTianyancha,
          label: '天眼查',
          url: `https://www.tianyancha.com/search?${qs.stringify({ key: keyword })}`,
        },
        {
          icon: icoQichacha,
          label: '企查查',
          url: `https://www.qcc.com/web/search?${qs.stringify({ key: keyword })}`,
        },
      ].map(item => (
        <a
          key={item.label}
          href={item.url}
          className="arco-link arco-link-hoverless"
          target="_blank"
          style={{ display: 'inline-flex', alignItems: 'center', marginRight: 16 }}
        >
          <img src={item.icon} style={{ marginRight: 4, width: 16, height: 16 }} />
          {item.label}
        </a>
      ))}
    </div>
  );
};

export const renderUnlockDate = (e: ShareUnlockInfo, handleFavorite: (id: string, cancel: boolean) => void) => {
  return (
    <div className={styles['unlock-date']}>
      <div className={styles.text}>{e.unlockDate}</div>
      <div className={styles.fav} onClick={() => handleFavorite(e.id, e.isFavorite)}>
        {e.isFavorite ? <IconStarFill style={{ color: 'rgb(var(--gold-6))' }} /> : <IconStar />}
      </div>
    </div>
  );
};

export const renderCapital = (e: ShareUnlockInfo) => {
  const unlockCapital = parseCapitalToString(e.shareUnlockCapital, e.shareUnlockCapitalType);
  return <div className={styles['unlock-capital']}> {unlockCapital}</div>;
};

export const renderSharePercent = (shareAmount: number, shareTotal: number) => {
  if (!shareTotal) {
    return '-';
  }
  return `${((shareAmount / (shareTotal ?? 1)) * 100).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  })}%`;
};

export const renderLink = (url: string) => {
  return url ? (
    <a className="arco-link arco-link-hoverless" href={url} target="_blank">
      {url}
    </a>
  ) : (
    <div>-</div>
  );
};

export const renderAddr = (addr: string) => {
  return addr ? (
    <a
      className="arco-link arco-link-hoverless"
      href={`http://api.map.baidu.com/geocoder?${qs.stringify({ address: addr, output: 'html' })}`}
      target="_blank"
    >
      {addr}
    </a>
  ) : (
    <div>-</div>
  );
};

export const renderExecutiveIntro = (executive: ExecutiveInfo) => {
  if (!executive.intro) {
    return '-';
  }
  const arr: JSX.Element[] = [];
  executive.highlightTagList.sort((a, b) => a.start - b.start);
  let idx = 0;
  const style1 = { backgroundColor: 'rgb(var(--yellow-3))' };
  const style2 = { backgroundColor: '#ead1ff' };
  executive.highlightTagList.forEach(span => {
    if (idx < span.start) {
      arr.push(<span key={idx}>{executive.intro.slice(idx, span.start)}</span>);
    }
    let style = style1;
    if (executive.eduTagList.find(v => v.start === span.start)) {
      style = style2;
    }
    arr.push(
      <span key={span.start} style={style}>
        {executive.intro.slice(span.start, span.end)}
      </span>,
    );
    idx = span.end;
  });
  if (idx < executive.intro.length) {
    arr.push(<span key={idx}>{executive.intro.slice(idx, executive.intro.length)}</span>);
  }
  return <span className={styles.intro}>{arr}</span>;
};

const nowDate = dayjs().format('YYYY-MM-DD');

export const renderExecutiveTags = (executive: ExecutiveInfo) => {
  const titleList = executive.titleList.sort((a, b) => getTitleScore(b) - getTitleScore(a));

  return (
    <div className={styles.titles}>
      {unique(titleList?.filter(title => !title.endDate || title.endDate >= nowDate)?.map(t => t.name))?.map(title => (
        <TagBadge key={title} className={styles.title} text={title} color="blue" />
      ))}
    </div>
  );
};

const ranks = {
  董事长: 10000000,
  非独立董事: 100000,
  董事: 10000,
  总裁: 1000,
  经理: 500,
  顾问: 400,
  总监: 300,
  主管: 200,
  财务: 100,
  监事: 50,
};

export const getTitleScore = (title: TitleListItem) => {
  let score = 0;
  if (!title.endDate || title.endDate >= nowDate) {
    score += 100000000;
  }
  let max = -1;
  Object.entries(ranks).forEach(([key, value]) => {
    if (title.name.includes(key)) {
      let v = value;
      if (title.name.includes('副')) {
        v /= 2;
      }
      if (v > max) {
        max = v;
      }
    }
  });
  if (max !== -1) {
    score += max - title.name.length;
  }
  return score;
};

export const getExecutiveScore = (e: ExecutiveInfo) => {
  let total = 0;
  let now = 0;
  e.titleList.forEach(title => {
    total += getTitleScore(title);
    if (!title.endDate || title.endDate >= nowDate) {
      total -= 100000000;
      now = 100000000;
    }
  });
  return total + now;
};
