import apis from '.';

export interface InitResp {
  algorithm: string;
  publicKey: string;
}

export async function init() {
  return apis.get<null, InitResp>('/api/v1/init');
}
