import { BrowserRouter } from '@modern-js/runtime/router';
import { KeepAlive } from 'react-fiber-keep-alive';
import { RecoilRoot } from 'recoil';

import './App.css';
import './global.less';
import MainLayout from './layouts/MainLayout';
import './utils/i18n';

const App = () => {
  return (
    <KeepAlive.Provider value={document.getElementById('root')}>
      <RecoilRoot>
        <BrowserRouter>
          <MainLayout />
        </BrowserRouter>
      </RecoilRoot>
    </KeepAlive.Provider>
  );
};

export default App;
