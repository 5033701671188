import React, { useEffect, useMemo } from 'react';

import { Route, Switch, useHistory, useLocation } from '@modern-js/runtime/router';
import qs from 'query-string';
import { useRecoilState } from 'recoil';

import Content from './Content';
import styles from './index.module.less';
import Menu from './Menu';

import { getProfile } from '@/apis/getProfile';
import Loading from '@/components/Loading';
import Login from '@/pages/Login';
import { globalState } from '@/recoil/global';
import { getFlattenRoutes, getRoutePath, matchRoute } from '@/route';
import { AuthInfo } from '@/utils/types';

const MainLayout: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [global, setGlobal] = useRecoilState(globalState);

  const { authInfo } = global;
  const setAuthInfo = (fn: (v: AuthInfo) => AuthInfo) => {
    setGlobal(v => ({ ...v, authInfo: fn(v.authInfo) }));
  };

  const flattenRoutes = useMemo(() => getFlattenRoutes() || [], []);
  const currentRoute = useMemo(
    () => flattenRoutes.find(r => matchRoute(r, qs.parseUrl(location.pathname).url)),
    [location],
  );
  const routePath = useMemo(() => getRoutePath(currentRoute), [location]);
  const menuRoute = useMemo(() => {
    const rs = routePath.filter(r => !r.hideInMenu);
    if (rs.length > 0) {
      return rs[0];
    }
    return undefined;
  }, [location]);

  useEffect(() => {
    switch (authInfo.authState) {
      case 'init':
        getProfile().then(async v => {
          const { data } = v;
          if (data) {
            setAuthInfo(p => ({ ...p, ...data, authState: 'success' }));
          } else {
            setAuthInfo(p => ({ ...p, authState: 'error' }));
          }
        });
        break;
      case 'success':
        if (location.pathname === '/login') {
          history.replace('/home');
        }
        break;
      case 'error':
        history.push('/login');
        break;
      default:
    }
  }, [authInfo]);

  return (
    <Switch>
      <Route exact={true} path="/login">
        <Login />
      </Route>
      <Route path="*">
        {authInfo.authState !== 'success' && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
        {authInfo.authState === 'success' && (
          <div className={styles.layout}>
            <Content routes={flattenRoutes} />
            <Menu selectedRoute={menuRoute} />
          </div>
        )}
      </Route>
    </Switch>
  );
};

export default MainLayout;
