import dayjs from 'dayjs';

export interface QueryShareUnlockListParams {
  page?: number;
  pageSize?: number;
  search?: string;
  stakeholderType?: StakeholderTypeOption[];
  unlockDateOption?: UnlockDateOption;
  customUnlockDate?: dayjs.Dayjs[];
  unlockCapitalOption?: UnlockCapitalOption;
  customUnlockCapitalStart?: number;
  customUnlockCapitalEnd?: number;
  addrProvince?: string;
  addrCity?: string;
  companyAddrProvince?: string;
  companyAddrCity?: string;
  stockCode?: string;
  sort?: SortOrder;
}

export type StakeholderTypeOption = '不限' | '高管' | '有限合伙' | '普通合伙';

export const stakeholderTypeOptions: Record<StakeholderTypeOption, string | undefined> = {
  不限: undefined,
  高管: 'executive',
  有限合伙: '有限合伙企业',
  普通合伙: '普通合伙企业',
};

export type UnlockDateOption =
  | '未解禁'
  | '未来1个月'
  | '未来3个月'
  | '未来6个月'
  | '未来1年'
  | '未来2年'
  | '未来3年'
  | '自定义';

export const unlockDateOptions: Record<UnlockDateOption, () => (dayjs.Dayjs | undefined)[] | undefined> = {
  未解禁: () => undefined,
  未来1个月: () => [undefined, dayjs().add(1, 'month')],
  未来3个月: () => [undefined, dayjs().add(3, 'month')],
  未来6个月: () => [undefined, dayjs().add(6, 'month')],
  未来1年: () => [undefined, dayjs().add(1, 'year')],
  未来2年: () => [undefined, dayjs().add(2, 'year')],
  未来3年: () => [undefined, dayjs().add(3, 'year')],
  自定义: () => undefined,
};

export type UnlockCapitalOption =
  | '不限'
  | '100万以内'
  | '100-500万'
  | '500-1000万'
  | '1000-5000万'
  | '5000万-1亿'
  | '1亿以上'
  | '自定义';

export const unlockCapitalOptions: Record<UnlockCapitalOption, () => (number | undefined)[] | undefined> = {
  不限: () => undefined,
  '100万以内': () => [undefined, 1000000],
  '100-500万': () => [1000000, 5000000],
  '500-1000万': () => [5000000, 10000000],
  '1000-5000万': () => [10000000, 50000000],
  '5000万-1亿': () => [50000000, 100000000],
  '1亿以上': () => [100000000, undefined],
  自定义: () => undefined,
};

export type SortOrder =
  | '默认'
  | '市值高的在前'
  | '市值低的在前'
  | '解禁早的在前'
  | '解禁晚的在前'
  | '先收藏的在前'
  | '后收藏的在前';

export const sortOrderMap: Record<SortOrder, string | undefined> = {
  默认: undefined,
  市值高的在前: 'capital_desc',
  市值低的在前: 'capital_asc',
  解禁早的在前: 'unlock_date_asc',
  解禁晚的在前: 'unlock_date_desc',
  先收藏的在前: 'favorite_asc',
  后收藏的在前: 'favorite_desc',
};
