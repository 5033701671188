import React, { useRef, UIEvent, useEffect, useState, useCallback } from 'react';

import { BackTop, Empty } from '@arco-design/web-react';
import cs from 'classnames';

import styles from './index.module.less';
import ShareUnlockItem from './ShareUnlockItem';

import { ShareUnlockListItem } from '@/apis';
import Loading from '@/components/Loading';
import { useI18n } from '@/hooks';
import { debounce } from '@/utils/scheduler';

interface Props {
  loading: boolean;
  onLoadMore: () => void;
  list: ShareUnlockListItem[];
  filterWrapperRef: React.RefObject<HTMLDivElement>;
}

const ShareUnlockList: React.FC<Props> = ({ loading, list, filterWrapperRef, onLoadMore }) => {
  const { t } = useI18n('pages.Main.ShareUnlockList');
  const listRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);
  const [visible, setVisible] = useState(true);

  const loadMore = useCallback(debounce(onLoadMore, 1000, true, false), [onLoadMore]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);

    const isScrollDown = lastScrollTop.current < scrollTop;
    const threshold = 100;

    if (isScrollDown && scrollBottom < threshold) {
      loadMore();
    }

    lastScrollTop.current = scrollTop;
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = lastScrollTop.current;
      setVisible(true);
    }
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <div ref={filterWrapperRef} className={styles.content}>
      {loading && list.length === 0 && <Loading />}
      {!loading && list.length === 0 && (
        <Empty
          className={styles.empty}
          description={
            <div>
              <div>{t('empty.0', '暂无数据')}</div>
              <div>
                {t('empty.2', '默认不展示过期的历史数据，如需查看，请点击右上角的筛选按钮手动选择历史解禁日期')}
              </div>
            </div>
          }
        />
      )}
      {!loading && list.length !== 0 && (
        <>
          <BackTop visibleHeight={800} style={{ position: 'absolute' }} target={() => listRef.current!} />
          <div
            ref={listRef}
            className={cs(styles.list, 'fh-scrollbar-y')}
            onScroll={handleScroll}
            style={{ visibility: visible ? undefined : 'hidden' }}
          >
            {list.map(item => (
              <ShareUnlockItem key={item.id} item={item} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ShareUnlockList;
