import React, { useEffect, useRef, useState } from 'react';

import { Drawer, Input } from '@arco-design/web-react';
import { RefInputType } from '@arco-design/web-react/es/Input/interface';
import { IconFilter, IconMenu } from '@arco-design/web-react/icon';
import { useRecoilState } from 'recoil';

import { QueryShareUnlockListParams } from '../types';

import Filter from './Filter';
import styles from './index.module.less';

import { useI18n } from '@/hooks';
import { globalState } from '@/recoil/global';

interface Props {
  defaultValue: QueryShareUnlockListParams;
  onSearch: (value: QueryShareUnlockListParams) => void;
  filterWrapperRef: React.RefObject<HTMLDivElement>;
  isFavorite?: boolean;
}

const SearchBar: React.FC<Props> = ({ defaultValue: params, onSearch, filterWrapperRef, isFavorite }) => {
  const ref = useRef<RefInputType>(null);
  const [, setGlobal] = useRecoilState(globalState);
  const { t } = useI18n('pages.Main.SearchBar');
  const [filterVisible, setFilterVisible] = useState(false);

  const hasFilterStakeholderType = params.stakeholderType !== undefined;
  const hasFilterUnlockDate = params.unlockDateOption !== undefined && params.unlockDateOption !== '未解禁';
  const hasFilterUnlockCapital = params.unlockCapitalOption !== undefined && params.unlockCapitalOption !== '不限';
  const isFiltered =
    Boolean(params.addrCity) ||
    Boolean(params.addrProvince) ||
    Boolean(params.companyAddrCity) ||
    Boolean(params.companyAddrProvince) ||
    Boolean(params.stockCode) ||
    hasFilterStakeholderType ||
    hasFilterUnlockDate ||
    hasFilterUnlockCapital;

  const collapseMenu = () => {
    setGlobal(g => ({ ...g, menu: { ...g.menu, visible: !g.menu.visible } }));
  };

  useEffect(() => {
    return () => {
      setFilterVisible(false);
    };
  }, []);

  return (
    <div className={styles['search-bar']}>
      <div className={styles['icon-btn']} onClick={collapseMenu}>
        <IconMenu />
      </div>
      <Input.Search
        ref={ref}
        className={styles['search-input']}
        placeholder={t('search.placeholder', '搜索姓名/股票/院校/公司等')}
        defaultValue={params?.search}
        onSearch={v => {
          onSearch({
            ...params,
            search: v,
          });
          ref.current?.blur();
        }}
        maxLength={300}
      />
      <div className={styles['icon-btn']} onClick={() => setFilterVisible(v => !v)}>
        <IconFilter style={{ color: isFiltered ? 'rgb(var(--primary-6))' : undefined }} />
      </div>
      <Drawer
        getPopupContainer={filterWrapperRef.current ? () => filterWrapperRef.current! : undefined}
        bodyStyle={{ padding: 0 }}
        height="80%"
        autoFocus={false}
        placement="top"
        title={null}
        footer={null}
        closable={false}
        visible={filterVisible}
        onCancel={() => setFilterVisible(false)}
      >
        <Filter
          defaultValue={params}
          onSearch={p => {
            onSearch(p);
            setFilterVisible(false);
          }}
          isFavorite={isFavorite}
        />
      </Drawer>
    </div>
  );
};

export default SearchBar;
