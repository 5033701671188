import React, { CSSProperties } from 'react';

import { Descriptions } from '@arco-design/web-react';
import cs from 'classnames';
import dayjs from 'dayjs';

import {
  renderCapital,
  renderExecutiveIntro,
  renderExecutiveTags,
  renderExternalLink,
  renderUnlockDate,
} from './common';
import ExecutiveTitleList from './ExecutiveTitleList';
import InnerUnlockList from './InnerUnlockList';
import styles from './TabBasicExecutive.module.less';

import { GetShareUnlockInfoResp } from '@/apis/getShareUnlockInfo';
import Help from '@/components/Help';
import { useI18n, useWindowSize } from '@/hooks';
import { parseShareAmountToString } from '@/utils/share';

interface Props {
  className?: string | string[];
  style?: CSSProperties;
  resp: GetShareUnlockInfoResp;
  handleFavorite: (id: string, cancel: boolean) => void;
}

const TabBasicExecutive: React.FC<Props> = ({ className, style, resp, handleFavorite }) => {
  const { t } = useI18n('pages.Info.TabBasicExecutive');
  const { executive, shareUnlock } = resp;
  const { width } = useWindowSize();
  const nowDate = dayjs().format('YYYY-MM-DD');

  if (!executive) {
    return <></>;
  }

  return (
    <div className={cs(styles.basic, className)} style={style}>
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.name}>{executive.name}</div>
          <div className={styles.gender}>{executive.gender}</div>
          <div className={styles.age}>
            {dayjs().diff(dayjs(executive.birth), 'year')}
            {t('age', '岁')}
          </div>
        </div>
        <div className={styles.time}>
          {dayjs(shareUnlock.updatedAt).format('YYYY-MM-DD ')}
          {t('updated_at', '更新')}
        </div>
      </div>
      {renderExecutiveTags(executive)}
      <Descriptions
        className={cs(styles.desc, width <= 900 && styles['desc-inline'])}
        valueStyle={{ wordBreak: 'break-all' }}
        layout={width <= 900 ? 'inline-vertical' : 'horizontal'}
        border={width > 900}
        data={[
          { label: t('unlock_date', '本次解禁时间'), value: renderUnlockDate(shareUnlock, handleFavorite) },
          { label: t('unlock_amount', '本次解禁数量'), value: parseShareAmountToString(shareUnlock.shareUnlockAmount) },
          {
            label:
              shareUnlock.unlockDate >= nowDate ? (
                <Help
                  content={t('unlock_capital', '本次解禁市值')}
                  help={t('unlock_capital.hint', '根据最新收盘价计算')}
                />
              ) : (
                t('unlock_capital', '本次解禁市值')
              ),
            value: renderCapital(shareUnlock),
          },
          { label: t('birth', '出生年份'), value: dayjs(executive.birth).format('YYYY年') },
          { label: t('edu_level', '学历'), value: executive.eduLevel || '-' },
          { label: t('nation', '国籍'), value: executive.nation || '-' },
          { label: t('intro', '简历'), value: renderExecutiveIntro(executive), span: 3 },
          { label: t('external_link', '外部链接'), value: renderExternalLink(executive.name), span: 4 },
        ]}
      />
      <ExecutiveTitleList executive={executive} trigger />
      <InnerUnlockList resp={resp} handleFavorite={handleFavorite} />
    </div>
  );
};

export default TabBasicExecutive;
