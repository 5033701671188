export const parseCapital = (capital: number, type: string) => {
  let unit = type;
  const units: [number, string][] = [
    [100000000, '亿'],
    [10000, '万'],
  ];
  for (const [num, text] of units) {
    if (capital >= num) {
      unit = `${text}${unit}`;
      // eslint-disable-next-line no-param-reassign
      capital /= num;
      break;
    }
  }
  return {
    capital: capital.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }),
    unit,
  };
};

export const parseCapitalToString = (capital?: number, type?: string, showZero?: boolean) => {
  if (!showZero && (!capital || !type)) {
    return '-';
  }
  const { capital: a, unit: b } = parseCapital(capital ?? 0, type ?? '元');
  return a + b;
};

export const parseShareAmount = (amount: number) => {
  if (amount < 10000) {
    return { amount: amount.toFixed(0), unit: '' };
  }
  let unit = '';
  const units: [number, string][] = [
    [100000000, '亿'],
    [10000, '万'],
  ];
  for (const [num, text] of units) {
    if (amount >= num) {
      unit = `${text}${unit}`;
      // eslint-disable-next-line no-param-reassign
      amount /= num;
      break;
    }
  }
  return { amount: amount.toFixed(2), unit };
};

export const parseShareAmountToString = (amount?: number) => {
  if (!amount) {
    return '-';
  }
  const { amount: a, unit: b } = parseShareAmount(amount);
  return a + b;
};
