import apis from '.';

export interface ProfileResp {
  id: string;
  name: string;
  username: string;
}

export async function getProfile() {
  return apis.get<null, ProfileResp>('/api/v1/profile');
}
