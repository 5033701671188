enum LoggerLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
}

const colors = {
  [LoggerLevel.info]: '#0d82f0',
  [LoggerLevel.debug]: '#13c46c',
  [LoggerLevel.warn]: '#f49508',
  [LoggerLevel.error]: '#ec3d0c',
};

class Logger {
  public level: LoggerLevel = LoggerLevel.debug;

  public debug(...args: any[]) {
    this.log(LoggerLevel.debug, ...args);
  }

  public info(...args: any[]) {
    this.log(LoggerLevel.info, ...args);
  }

  public warn(...args: any[]) {
    this.log(LoggerLevel.warn, ...args);
  }

  public error(...args: any[]) {
    this.log(LoggerLevel.error, ...args);
  }

  private log(level: LoggerLevel, ...args: any[]) {
    if (this.level <= level) {
      // eslint-disable-next-line no-console
      console[LoggerLevel[level] as keyof typeof LoggerLevel](
        `%c [${LoggerLevel[level]}]:`,
        `color: ${colors[level]}; font-weight: 700`,
        ...args,
      );
    }
  }
}

const logs = new Logger();

export default logs;
